.switch {
  display: grid;
  place-items: center;
  gap: 1em 0;
  margin: 0 0 0 -3px;
}

.switch-btn {
  --w: 25px;
  --bg: #ffffff2e;
  --bga: rgba(var(--rgb), 0.4);
  -webkit-appearance: none;
  border: none;
  font-size: 8px;
  width: 25px;
  height: 25px;
  background: var(--bg);
  box-shadow: 0 2px 8px 1px var(--bga);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.1s transform, 0.3s box-shadow;
  will-change: transform, box-shadow;
  backface-visibility: hidden;
  padding: 0;
}

.switch-btn > * {
  pointer-events: none;
}

.switch-btn:focus {
  outline: none;
}

.switch-btn:active {
  transform: scale(0.97);
}

.switch-btn:after {
  --bg: rgb(var(--rgb));
  content: "";
  position: absolute;
  top: -15%;
  left: -15%;
  width: 200%;
  height: 200%;
  background: var(--bg);
  border-radius: inherit;
  transform: translate(5%, 5%) scale(0.03);
  pointer-events: none;
}

.switch-btn.animating:after {
  animation: c 0.5s cubic-bezier(0.5, 0, 0.5, 1) backwards;
}

.switch-btn.active,
.switch-btn:not(.active):after {
  --rgb: 255 108 0;
}

.switch-btn:not(.active),
.switch-btn.active:after {
  --rgb: 255 108 0;
}

@keyframes c {
  to {
    transform: none;
  }
}

.switch-btn span {
  display: inline-block;
  position: relative;
}

.switch-btn b {
  --w: 3px;
  overflow: hidden;
  border-radius: var(--w);
  display: grid;
  width: var(--w);
  height: 23px;
  top: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 3;
  will-change: transform;
}

.switch-btn b:before {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: var(--w);
  transform: translateY(-15px);
  transition-timing-function: var(--timing);
  transition: 0.5s;
}

.switch-btn.active b:before {
  transform: translateY(12px);
  transition-delay: 0.27s;
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.25, 1.25);
}

.switch-btn svg {
  --dash: 195;
  stroke-linecap: round;
  stroke-dasharray: var(--dash);
  stroke-dashoffset: var(--dash);
  width: 16px;
  height: 16px;
  transform: scaleX(-1) rotate(-89deg);
  transition: 0.4s;
  transition-timing-function: var(--timing);
  margin: auto;
  position: relative;
  z-index: 1;
  will-change: transform, stroke-dashoffset;
}

.switch-btn:not(.active) svg {
  stroke-dashoffset: 40;
  transform: scaleX(-1) rotate(-62deg);
  transition: 0.5s 0.25s;
}

aside {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0 2em 2em;
  font-family: "Pacifico", cursive;
}

aside a {
  text-decoration: underline;
  color: #e65289;
  display: flex;
  align-items: center;
}

aside a:hover,
aside a:focus {
  color: #000;
  text-decoration: none;
}

.dribbble-logo {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.pro-card-wrapper li img {
  width: 100%;
  max-width: 20px;
  filter: brightness(0) saturate(100%) invert(81%) sepia(0%) saturate(533%)
    hue-rotate(135deg) brightness(89%) contrast(105%);
}
.pro-card-wrapper li:hover img {
  filter: none;
}
.pro-card-wrapper li:hover {
  color: #fff;
}

button.switch-btn.active {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
}
