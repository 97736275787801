.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin: 10px 0;
  gap: 10px;
}
.input-container .two-inputs {
  display: flex;
  width: 100%;
  gap: 10px;
}

.multi-input-cont {
  display: flex;
  justify-content: start;
  align-items: baseline;
  width: 100%;
}

.input-container:nth-last-child() {
  display: flex;
  justify-content: end;
  align-items: end;
}

.tbbox label.disabled input {
  background-color: #26282a;
  -webkit-text-fill-color: #6c6d6e;
  /* border-color: #26282a; */
  border: none;
}
.tbbox label.disabled span {
  color: #6c6d6e !important;
}

.tbbox label {
  width: 100%;
  position: relative;
  margin-bottom: 1vw;
}
.tbbox span {
  text-transform: uppercase;
}
.input-error {
  border: 1px solid red !important;
}
.tbbox label span {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #ede5ce;
  transition: all 0.5s ease;
}

.tbbox label input,
.tbbox label select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #181c1f inset;
  -webkit-text-fill-color: #e3ca99;
}

.tbbox label input,
.tbbox label select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #181c1f inset;
  -webkit-text-fill-color: #e3ca99;
  outline: none;
}
.tbbox .btn.link-btn {
  padding: 20px;
  margin: 0;
}

.tbbox label input,
.tbbox label select {
  width: 100%;
  padding: 20px 34px 20px 12px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  background: #181c1f;
  color: #ede5ce;
  font-family: "Roboto";
  outline: none;
  border-radius: 8px;
  font-size: 16px;
}
.tbbox .multi-input-cont label::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 50px;
  height: 58px;
  background-color: #d0a755;
  color: #000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #d0a755;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-disabled {
  background-color: #2c2517;
}
.btn-disabled:hover {
  background-color: #2c2517;
  cursor: default;
}

/* player id  */

.tbbox label input.wallet-input {
  padding-left: 50px;
}
.tbbox label.wallet-label.has-value span {
  left: 50px;
}
.tbbox label.wallet-label span {
  top: 67%;
  left: 50px;
}
.tbbox .multi-input-cont label .wallet-icon {
  position: absolute;
  left: 8px;
  top: 4%;
  display: block;
  width: 30px;
  height: 100%;
  margin-left: 8px;
}
button.wallet-adapter-button.wallet-adapter-button-trigger {
  background: transparent;
  font-size: 0;
  padding: 0 !important;
  height: 100%;
}
.tbbox .multi-input-cont label .copy-btn img:hover {
  transform: scale(1.2);
}
.tbbox .multi-input-cont label .copy-btn img {
  position: absolute;
  right: 14px;
  top: 16px;
  z-index: 8;
  transition: 0.3s;
}

.tbbox label.has-value span {
  top: 14px;
  left: 12px;
  font-size: 11px;
  color: #b3b3b3;
}

/* profile details  */
.input-container .date-input-container input {
  color-scheme: dark;
}
.input-container .date-input-container .day {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.input-container .date-input-container .year {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.country-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-container .date-input-container {
  width: 100%;
  display: flex;
  align-items: start;
}

.input-container .dateicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0px 0px 8px 0px;
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  background: #181c1f;
  color: #ede5ce;
  font-family: "Roboto";
  outline: none;
  border-radius: 8px;
  font-size: 16px;
}

/* account settings */
.accountsetting .input-container {
  gap: 10px;
}
.warning-modal .btn.pw-warning {
  font-size: 10px;
  padding: 6px 6px;
}

.accountsetting .update-btn {
  margin-top: 20px;
}

/* 2 FA settings  */

.twofa p.warning {
  color: #d0a755;
  position: relative;
  padding-left: 25px;
}
.twofa p.warning::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  display: block;
  width: 17px;
  height: 17px;
  background-image: url("https://d2j04pqii01bv9.cloudfront.net/Images/warning-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.twofa .input-container img.qr-img {
  width: 100px;
  height: 100px;
  margin: 20px 0;
}

.twofa .switch-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 5px 0 5px;
  width: 100%;
}
.twofa .switch-cont label {
  margin: 0;
}

/* update preferences */
.updatepreferences .check-box input {
  background-color: transparent;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .input-container .two-inputs {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .tbbox label span {
    font-size: 12px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .tbbox label span {
    font-size: 12px;
    left: 10px;
  }
  .tbbox label input,
  .tbbox label select {
    padding: 17px 10px;
  }
}
