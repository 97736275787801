.table-container {
  overflow-x: auto;
  margin: 20px 30px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 100vh;
}

.table-container .btn-container {
  display: flex;
  justify-content: end;
  margin: 20px 0;
  gap: 10px;
}
.table-container .btn-container button {
  background: transparent;
  border: 1px solid #d0a755;
  box-sizing: border-box;
  color: #d0a755;
  padding: 5px 10px;
  cursor: pointer;
}

.table-container .btn-container button.disabled {
  border-color: #39372a;
}

.table-container .btn-container .btn-prev.disabled:before,
.table-container .btn-container .btn-next.disabled:before {
  opacity: 0.25;
}

.table-container .btn-container button:hover {
  border: 1px solid #ffe39e;
}

.btn-container .norecord {
  position: relative;
  left: 150%;
  margin: 30px 0;
  color: #d0a755;
}
.table-container .btn-container button.disabled:hover::before {
  opacity: 1;
}

.table-container .btn-container .btn-next:before {
  content: "";
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/arrowr.svg);
  background-size: contain;
  width: 30px;
  height: 30px;
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  width: 22px;
}

.table-container .btn-container .btn-prev:before {
  content: "";
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/arrowl.svg);
  background-size: contain;
  width: 30px;
  height: 30px;
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  width: 22px;
}

.table-main {
  border: 1px solid #d0a755;
  border-collapse: collapse;
}

.table-main .table {
  width: 100%;
  border-collapse: collapse;
  color: #fff;
  background-color: #08121c;

  min-width: 70vw;
}

th,
td {
  padding: 10px 2rem;
  border: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 25.5px;
  text-align: left;
  text-wrap: nowrap;
}

th {
  color: #b3b3b3;
  position: sticky;
  top: 0;
}

td {
  color: #ede5ce;
}

tr.highlight {
  background-color: #31322f;
  border: none;
}

tr.highlight td span {
  color: #020509;
  background-color: #d0a755;
  font-family: Roboto;
  padding: 2px 4px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin: 0 10px;
}

.norecord {
  padding: 30px 0;
  font-size: 16px;
}

.norecord td {
  color: #d0a755;
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  th,
  td {
    padding: 8px 0.5rem;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 1365px) {
  .table {
    min-width: 95vw;
  }
}
