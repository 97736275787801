.custom-table {
  width: 100%;
}

.custom-table table {
  width: 100%;
  border-spacing: 0;
  border-radius: 16px;
  overflow: auto;
}
.custom-table table::-webkit-scrollbar,
.custom-table .table-bg::-webkit-scrollbar {
  width: 0px;
}
.custom-table .table-bg {
  background: #ffffff0a;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  overflow: auto;
  /* height: 63vh; */
}

.home-table-flex .custom-table .table-bg {
  height: calc(100vh - 230px) !important;
  overflow-x: hidden;
}

.custom-table table thead tr th {
  border-bottom: 1px solid #ffffff80;
  padding: 20px 30px 10px 30px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
  position: relative;
}

.custom-table table tbody tr.no-data:hover td,
.custom-table table tbody tr.no-data td {
  color: #ffffff99;
}
.custom-table table tbody tr td div {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #ffffff99;
}

.custom-table table tbody tr td div img {
  width: 24px !important;
  height: 24px !important;
  object-fit: cover;
}

.custom-table table tbody tr td {
  font-family: Montserrat;
  border-bottom: 1px solid #ffffff1f;
  padding: 10px 0px 10px 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.no-data td {
  height: 300px !important;
  width: 100% !important;
  text-align: center !important;
  padding: 0 !important;
}
.no-data:hover {
  background: transparent !important;
}
.load-more-btn {
  border: none;
  margin: 10px auto;
}

body .load-full {
  height: 70vh;
  width: 90%;
  overflow: hidden;
  position: relative;
  display: block;
}
.custom-table .load-full {
  display: table-row !important;
  width: 100%;
  height: 450px !important;
}

body .load-full .loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  bottom: 0;
}

.skeleton-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e0e0e036;
  animation: shimmer 3s infinite;
}

.skeleton-name {
  width: 80px;
  height: 10px;
  background-color: #e0e0e036;
  border-radius: 4px;
  animation: shimmer 3s infinite;
}

.skeleton-coin-text {
  width: 70px;
  height: 10px;
  background-color: #e0e0e036;
  border-radius: 4px;
  animation: shimmer 3s infinite;
}

.skeleton-coin-price {
  width: 60px;
  height: 10px;
  background-color: #e0e0e036;
  border-radius: 4px;
  animation: shimmer 3s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-avatar,
.skeleton-name,
.skeleton-coin-text,
.skeleton-coin-price {
  background: linear-gradient(
    to right,
    #e2dede6e 0%,
    #e0e0e05e 20%,
    #f0f0f03c 40%,
    #f0f0f056 100%
  );
  background-size: 200% 100%;
}

.custom-table table tbody tr td.first-col {
  display: flex;
  align-items: center;
  gap: 17px;
}

.custom-table table tbody .skeleton-row:hover {
  background: #ffffff0a;
}

.custom-table table tbody .skeleton-row:hover td {
  background-clip: unset !important;
  -webkit-background-clip: unset !important;
  background: transparent;
}

.custom-table table tbody .skeleton-row:hover td div {
  background-clip: unset !important;
  -webkit-background-clip: unset !important;
  background: linear-gradient(
    to right,
    #e2dede6e 0%,
    #e0e0e05e 20%,
    #f0f0f03c 40%,
    #f0f0f056 100%
  );
}
