#animation_container {
  background-color: rgba(0, 0, 0, 0);
  width: 100% !important;
  height: auto !important;
  margin: "auto";
  position: relative;
}

#canvas {
  background-color: gba(0, 0, 0, 0);
  width: 100% !important;
  height: auto !important;
}

#dom_overlay_container {
  pointer-events: none;
  overflow: hidden;
  width: 100% !important;
  height: auto !important;
}
.popup {
  border: 1px solid #d0a755;
  background: #000000b1;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;
  width: 500px;
  z-index: 999;
  border-radius: 10px;
  left: 50%;
  transform: translate(-50%, -200px);
}
.popup p {
  color: #d0a755;
  letter-spacing: 1px;
  margin: 0;
  font-size: 13px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .popup {
    width: 70vw;
    transform: translate(-50%, -300px);
  }
  .popup p {
    font-size: 12px;
  }
}
