.highscores-page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg21.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.highscores-form {
  /* padding: 2.2vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 30px;
}

.highscores-form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 20vw;
}

.highscores-form form label {
  width: 100%;
  position: relative;
  margin-bottom: 1vw;
}

.highscores-form form label input {
  width: 100%;
  padding: 15px 34px 15px 12px;
  border: none;
  background: #26282a;
  font-size: 14px;
  color: #ede5ce;
  font-family: "Roboto";
  outline: none;
  border-radius: 8px;
  box-sizing: border-box;
}

.highscores-form form label::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 50px;
  height: 45px;
  background-color: #d0a755;
  color: #000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #d0a755;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.highscores-form form label span {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  letter-spacing: 1px;
  color: #6c6d6e;
}

.highscores-form form label.has-value span {
  top: 10px;
  left: 12px;
  font-size: 10px;
  color: #6c6d6e;
  font-weight: 300;
}

.highscores-form form label .search-btn {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
}

.highscores-form form label .search-btn img:hover {
  transform: scale(1.2);
}
.highscores-form form label .search-btn img {
  position: absolute;
  right: 27px;
  top: 14px;
  z-index: 8;
  transition: 0.3s;
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .highscores-form form {
    width: 60vw;
  }
  .highscores-form form label span {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mb5 {
    margin-bottom: 30px;
  }
  .highscores-form form {
    width: 40vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .highscores-form form {
    width: 30vw;
  }
}
