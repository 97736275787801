section.error-page {
  height: 89.4vh;
  position: relative;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/not-found-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

section.error-page .error-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

section.error-page .error-detail .error-content {
  padding: 10px;
}

section.error-page .error-detail .error-content h3 {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #ede5ce;
}

section.error-page .error-detail .error-content p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #b3b3b3;
}

section.error-page .error-detail .error-content a {
  padding: 10px 20px;
  margin: 30px 0 0 0;
  display: inline-block;
  border: 1px solid #d0a755;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.05em;
  background-color: #d0a755;
  color: #0f0d0d;
  text-decoration: none;
  border-radius: 10px;
}

.error-img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.error-img img {
  width: 100%;
  max-width: 90%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  section.error-page .error-detail {
    flex-direction: column;
  }

  section.error-page {
    height: 77vh;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  section.error-page .error-detail {
    flex-direction: column;
  }

  section.error-page {
    height: 94vh;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  section.error-page .error-detail {
    flex-direction: column;
  }

  section.error-page {
    height: 94vh;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
}
