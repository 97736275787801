.tabl {
  padding: 14px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 12px;
  margin: 10px 0;
  justify-content: space-between;
}

.tabl .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 10px;
}

.tabl img.check-icon {
  padding: 0 8px;
}

.tabl.active {
  background: #191c1f;
  color: #d0a755;
}

.iconlist {
  margin-top: 20px;
}
