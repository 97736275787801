.d-bg-primary-card.currency-wrapper.d-card {
  position: absolute;
  width: 200px;
  margin: 20px 0 0 -60px;
  border-radius: 0;
  height: 300px;
  overflow: auto;
  padding: 15px 0;
  box-sizing: border-box;
  background: #020509;
  visibility: hidden;
  transition: all 0.5s ease;
}

body .currency-active {
  visibility: visible !important;
}
.d-currency-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.currency-list li.cur-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  padding: 7px 10px;
  cursor: pointer;
}

.currency-wrapper::-webkit-scrollbar {
  width: 1em;
}

.currency-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(78 20 20);
  background-color: #191c1f;
}

.currency-wrapper::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  outline: 1px solid slategrey;
}

.currency-list li.cur-item h3.currency-title {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #b3b3b3;
}

.currency-list li.cur-item:hover {
  background: #ffffff0d;
}

.currency-list li.cur-item:last-child {
  margin: 0;
}

.d-currency-icon span {
  font-size: 17px;
  font-weight: 400;
  color: #ede5ce;
  font-family: "Roboto";
}
