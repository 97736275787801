.wel-banner {
  position: relative;
  height: 900px;
}

.wel-banner > .container {
  position: absolute !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.wel-banner .topbanner {
  padding: inherit;
  height: 900px;
  display: flex;
}

.wel-banner .topbanner h1 {
  font-size: 120px;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 800;
  line-height: 120px;
  letter-spacing: -3px;
  padding: 0 15%;
  margin: 0;
}

.wel-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2124297d;
  z-index: 99;
  top: 0;
  left: 0;
}

.wel-banner .container {
  position: relative;
  z-index: 999;
  gap: 10px;
}

.wel-banner .topbanner p {
  margin: 0;
  font-family: "montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffffb3;
}
.wel-banner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(360deg, #08121c, transparent);
  z-index: 99;
  bottom: 0;
  left: 0;
}

.welcome-intro .col-2 {
  width: 48%;
}

.welcome-intro .col-2 h3 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #fff;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

.welcome-intro .col-2 h3.dot:after {
  content: "";
  background: linear-gradient(121deg, #eeff00, #ffaa00);
  border-radius: 50px !important;
  width: 20px;
  height: 20px;
  display: inline !important;
}

.welcome-intro {
  padding: 3% 0;
}

.welcome-intro p {
  display: flex;
  gap: 5px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #ffffffcc;
}

.welcome-intro p .green {
  color: #22b416;
  font-style: normal;
}

.welcome-intro span {
  background: #ffffff0a;
  border-radius: 12px;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  display: block;
  margin: 30px 0 20px 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #ffffffcc;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.welcome-intro .wel-video video {
  border-radius: 13px !important;
  display: table !important;
}

.welcome-intro .wel-video:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 18px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}

.welcome-intro .wel-video {
  position: relative;
  padding: 0 3px 3px 3px;
  margin: 0 0 30px 0;
}

.welcome-intro .col-2 .d-btn {
  width: 50%;
  padding: 31px 25px !important;
  font-weight: 800 !important;
}

.welcome-intro .col-2 button.d-bg-secondary-contained {
  padding: 26px 25px !important;
}

.wel-footer p {
  padding: 30px 0;
  text-align: center;
  border-top: 1px solid #ffffff33;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
  line-height: 20px;
  color: #fff;
}
.wel-hero-come {
  background: url("https://d2j04pqii01bv9.cloudfront.net/dashboard-images/bottom-shade.png");
  background-position: bottom center;
  background-size: 100% 50%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}
.welcome-logo img {
  width: 400px !important;
}
.welcome-intro .col-2 .flex {
  position: relative;
  z-index: 999;
  gap: 60px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .welcome-logo img {
    width: 100% !important;
  }
  .wel-banner .topbanner h1 {
    font-size: 42px;
    padding: 0;
    letter-spacing: 0;
    line-height: 61px;
  }

  .wel-banner > .container {
    margin: 0 auto;
  }

  .wel-banner > .container img {
    width: 100%;
    margin: 0 auto;
    display: table;
    max-width: 70%;
  }

  .wel-banner {
    height: 650px;
  }

  .wel-banner .topbanner {
    height: 650px;
  }

  .welcome-intro .col-2 {
    width: 100%;
    margin-top: 60px;
  }

  .welcome-intro .col-2 h3 {
    font-size: 18px;
  }

  .welcome-intro p {
    font-size: 10px;
  }

  .welcome-intro span {
    letter-spacing: 0px;
    font-size: 11px;
  }

  .welcome-intro .col-2 .flex {
    flex-direction: column;
  }

  .welcome-intro .col-2 .d-btn {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .wel-banner .topbanner h1 {
    font-size: 42px;
    padding: 0;
    letter-spacing: 0;
    line-height: 61px;
  }

  .wel-banner > .container {
    margin: 0 auto;
  }

  .wel-banner > .container img {
    width: 100%;
    margin: 0 auto;
    display: table;
    max-width: 70%;
  }

  .wel-banner {
    height: 650px;
  }

  .wel-banner .topbanner {
    height: 650px;
  }

  .welcome-intro .col-2 {
    width: 100%;
    margin-top: 60px;
  }

  .welcome-intro .col-2 h3 {
    font-size: 18px;
  }

  .welcome-intro p {
    font-size: 10px;
  }

  .welcome-intro span {
    letter-spacing: 0px;
    font-size: 11px;
  }

  .welcome-intro .col-2 .flex {
    flex-direction: column;
  }

  .welcome-intro .col-2 .d-btn {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .welcome-intro .col-2 {
    width: 100%;
    margin-top: 60px;
  }

  .wel-banner .topbanner h1 {
    font-size: 80px;
    padding: 0;
    line-height: 100px;
  }

  .wel-banner {
    height: 700px;
  }

  .wel-banner .topbanner {
    height: 670px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .wel-banner .topbanner h1 {
    padding: 0;
    font-size: 100px;
  }
  .wel-banner {
    height: 700px;
  }

  .wel-banner .topbanner {
    height: 700px;
  }

  .welcome-intro .col-2 h3 {
    font-size: 23px;
  }

  .welcome-intro span {
    letter-spacing: 1px;
    font-size: 11px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .wel-banner .topbanner h1 {
    padding: 0 60px;
  }
}
