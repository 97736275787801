.d-model {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}
.d-model.active-model {
  display: flex;
  transition: all 0.5s ease;
  visibility: visible;
}
.d-model-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: rgb(0 0 0 / 80%);
  backdrop-filter: blur(19px);
  z-index: -1;
}
.d-model-content {
  width: 440px;
  background: rgb(255 255 255 / 8%);
  border-radius: 24px;
  padding: 40px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}


.hero-model .d-model-content {
  padding: 40px 20px;
}
.d-model.step-modal .d-model-content {
  height: 525px !important;
}
.d-model-content .d-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 20px;
  box-sizing: border-box;
}

.d-model-content .d-model-header ul.d-model-steps {
  display: flex;
  gap: 4px;
}

.d-model-content .d-model-header ul.d-model-steps li {
  transition: all 0.5s ease;
  width: 8px;
  height: 8px;
  background: rgb(255 255 255 / 20%);
  border-radius: 30px;
}

.d-model-content .d-model-header img {
  width: 100%;
  max-width: 30px;
  cursor: pointer;
}

.d-model-content .d-model-header ul.d-model-steps .active-step {
  width: 20px;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
}

.d-model-content .d-model-icon {
  text-align: center;
  padding: 10px 0px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.d-model-content .d-model-icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 460px;
  top: -10px;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
  background: url("https://d2j04pqii01bv9.cloudfront.net/dashboard-images/gradient-bg-shade.png");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.d-model-content .d-model-text {
  text-align: center;
}

.d-model-content .d-model-text h3 {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  /* margin-bottom: 20px; */
  /* max-width: 315px; */
  /* margin: auto; */
}

.d-model-content .d-model-text p {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  color: rgb(255 255 255 / 60%);
  line-height: 27px;
}

.d-model-content .d-model-text .w-full {
  width: 100%;
}

.d-model-content .d-model-text .full-contain {
  width: 100%;
}

.d-model-icon.goh-circle img {
  padding: 10px;
  border-radius: 51px;
  background: #ffe463;
  margin-bottom: 20px;
}

.d-model-content .d-model-text .icon-point {
  justify-content: center;
  margin-bottom: 30px;
}

.d-model-content .d-model-text .icon-point h4 {
  font-size: 20px;
  color: #fff;
  font-family: "Gilroy";
}

.d-content {
  transform: scale(1);
}
.d-wraper-modal-open .d-content {
  animation: quickScaleDown 0s 0s linear forwards;
}

.d-model-overlay {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.d-wraper-modal-open .d-model-overlay {
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.d-model-content {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.d-wraper-modal-open .d-model-content {
  opacity: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.heros-image-slider .swiper-slide.swiper-slide-prev,
.heros-image-slider .swiper-slide.swiper-slide-next
{
  height: 85%
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}
@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgb(0 0 0 / 38%);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .d-model.step-modal .d-model-content{
    height: auto !important;
  }  

  .d-model-content {
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    justify-content: flex-start;
  }
  .dawer-sidebar.d-active-siderbard .d-profile-content {
    width: 87%;
}
  /* .d-model-content .d-model-text h3{
    font-size: 23px;
    max-width: 250px;
  } */

  .d-model-content::-webkit-scrollbar {
    width: 0px !important;
  }
  .d-model-content::-webkit-scrollbar-thumb {
    outline: 0px solid #ffffff33 !important;
  }

  body .heros-image-slider .swiper-slide.swiper-slide-active {
    width: 230px !important;
    height: 85%;
  }

  body .heros-image-slider .swiper-wrapper {
    height: 330px;
    transform: translate3d(-124px, 0px, 0px) !important;
  }

  .heros-image-slider .swiper-slide.swiper-slide-prev {
    margin-left: -86px !important;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .d-model-content {
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    justify-content: flex-start;
  }
  .d-model-content::-webkit-scrollbar {
    width: 0px !important;
  }
  .d-model-content::-webkit-scrollbar-thumb {
    outline: 0px solid #ffffff33 !important;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .daily-modal .d-model-content {
    height: auto !important;
}

  .dawer-sidebar.d-active-siderbard .d-profile-content {
      width: 90%;
  }

  .d-model-content {
    max-width: 90%;
    /* height: calc(100% - 40%); */
    overflow-y: auto;
    justify-content: flex-start;
  }
  .d-model-content::-webkit-scrollbar {
    width: 0px !important;
  }
  .d-model-content::-webkit-scrollbar-thumb {
    outline: 0px solid #ffffff33 !important;
  }
  body .heros-image-slider .swiper-wrapper {
    height: 370px;
    transform: translate3d(-140px, 0px, 0px) !important;
  }


  /* body .d-model-main {
    padding: 0 0 30px 0;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  body .heros-image-slider .swiper-wrapper {
    height: 310px;
    transform: translate3d(-142px, 0px, 0px) !important;
  }


  body .d-model-main {
    padding: 0 0 30px 0;
  }
  /* .d-model-content .d-model-text h3 {
    margin: 0;
  } */
  .d-model-content .d-model-text p {
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  body .heros-image-slider .swiper-wrapper {
    height: 340px;
    transform: translate3d(-142px, 0px, 0px) !important;
  }


  .d-model-main {
    padding: 0 0 30px 0;
  }
}
