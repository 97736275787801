.icon-point {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  position: relative;
}
.tool-icon {
  width: 100%;
  max-width: 23px;
}
.icon-point span {
  font-family: "Gilroy";
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.d-icon {
  width: 60px;
  height: 60px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
}

.d-icon img {
  width: 100%;
  max-width: 28px;
}

.d-task-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.d-task-content h3 {
  padding: 0 10px 10px 10px;
  height: 50px;

}

.d-task-card.light-opacity.d-card {
  transition: all 0.5s ease;
  opacity: 0.5;
  /* pointer-events: none; */
}
.d-task-card.light-opacity-disable.d-card {
  transition: all 0.5s ease;
  opacity: 0.5;
  pointer-events: none;
}

.d-task-card.light-opacity.d-card::before {
  opacity: 0;
}

.d-task-card.light-opacity.d-card:hover::before {
  opacity: 1;
}

.d-task-card.light-opacity.d-card:hover {
  opacity: 1;
}
