.d-hero-icon {
  position: relative;
  text-align: center;
  padding-bottom: 20px;
  width: 55%;
  margin: 0 auto;
  user-select: none;
}

/* .d-hero-icon:before {
  content: "";
  background: url("https://d2j04pqii01bv9.cloudfront.net/dashboard-images/heor-bg.png");
  position: absolute;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: spin 30s linear infinite;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
} */

.d-hero-icon .hero-bg-shap {
  width: 100%;
  max-width: 45%;
  margin: 0 auto -40px auto;
  display: block;
}

.d-hero-col .d-home-table.d-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-hero-icon img.d-hero-iconimg {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
}

.inline-width {
  display: inline-block;
  width: 60%;
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }
  24% {
    transform: translate(1px, 30px);
  }
  50% {
    transform: translate(1px, 12px);
  }
  74% {
    transform: translate(1px, 22px);
  }
  100% {
    transform: translate(1px, 22px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated-image {
  position: absolute;
  height: auto;
  animation: moveUp 1s ease-in-out forwards;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-80px); /* Adjust the distance as needed */
    opacity: 0;
  }
}
