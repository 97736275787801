.media .tbbox:nth-child(1) .logtad img,
.media .tbbox:nth-child(2) .logtad img {
  width: 60%;
}

.media .tbbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px;
  border: 1px solid #d0a755;
  margin-bottom: 40px;
  background: rgb(8 18 28 / 75%);
}

.media .tbbox h5 {
  width: 100%;
  flex: 0 0 100%;
  color: #ede5ce;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
}

.media .tabbox a.btn {
  margin-left: auto;
}

.media .tbbox a.btn {
  margin-left: auto;
}

.media .logtad {
  width: 100%;
  display: flex;
  align-items: center;
}

.media .tbbox:last-child {
  margin: 0;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .media .tbbox {
    padding: 20px;
  }

  .media .tbbox h5 {
    font-size: 20px;
  }
}
