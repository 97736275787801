.d-home-table {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  z-index: 10;
}

.home-table-flex {
  align-items: stretch;
}

.d-table-col {
  width: 35%;
}

.d-hero-col {
  width: 65%;
  user-select: none;
}

.d-home-table .d-top-bar {
  margin-bottom: 30px;
}

.boot-btn {
  padding: 13px 40px !important;
  background-size: 100%;
}

.total-count {
  margin: 0 auto;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 0 0 60px;
  position: relative;
  width: 200px;
}

.total-count img {
  width: 100%;
  max-width: 50px;
  position: absolute;
  left: 0;
}

.total-count .d-gradient-text {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
  margin: 0;
}

.total-count .d-gradient-text span {
  font-size: 44px !important;
  white-space: nowrap;
}

.d-point-bar {
  width: 100%;
  max-width: 60%;
  border-radius: 20px;
  margin: 10px auto;
  padding: 30px 30px;
  background: #ffffff14;
  border: 2px solid #ffaa00;
  box-sizing: border-box;
}

.d-point-bar div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.d-point-bar div .battery {
  width: 16px;
  height: 11px;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 2px;
  padding: 0px 3px;
  box-sizing: border-box;
}

.d-point-bar div .battery:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
  right: -2px;
  border-radius: 20px;
  z-index: -1;
}

.battery img {
  width: 2px;
  height: 70%;
  object-fit: contain;
}
.d-point-bar div p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0;
  color: #ffffff;
  gap: 4px;
}

.d-point-bar div p p {
  color: #fff;
  opacity: 40%;
}
body .custom-table table tbody tr td:nth-child(2) span {
  /* max-width: 170px !important; */
  padding: 0 !important;
  display: block;
}
.custom-table table tbody tr td:nth-child(2) {
  color: #ffffff99;
}
.custom-table table tbody tr td span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px !important;
}

.d-table-col .d-home-table .custom-table {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.d-table-col .d-home-table .custom-table:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 20%;
  background: linear-gradient(360deg, #33363b, transparent);
  z-index: 999;
  bottom: 0;
  left: 0;
}

/* table animation */
.d-home-table tbody > tr {
  animation-fill-mode: forwards;
}

.d-home-table tbody > tr:first-of-type {
  animation: 0.5s ease-out 0s 1 normal none running animationfirst !important;
  
}
@keyframes animationfirst {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0;
  }
  90% {
    transform: translateY(-10%);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.d-home-table tbody > tr:nth-of-type(2n) {
  animation: 0.5s ease-out 0s 1 normal none running animation1;
}

@keyframes animation1 {
  0% {
    transform: translate(0px, -100%);
  }
  100% {
    transform: translate(0px, 0%);
  }
}
.d-home-table tbody > tr:nth-of-type(2n + 1) {
  animation: 0.5s ease-out 0s 1 normal none running animation13;
}

@keyframes animation13 {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.d-home-table tbody > :nth-of-type(1) {
  animation-delay: 0s;
}

.d-home-table tbody :nth-of-type(2) {
  animation-delay: 0.15s;
}

.d-home-table tbody :nth-of-type(3) {
  animation-delay: 0.03s;
}

.d-home-table tbody :nth-of-type(4) {
  animation-delay: 0.045s;
}

.d-home-table tbody :nth-of-type(5) {
  animation-delay: 0.06s;
}

.d-home-table tbody :nth-of-type(6) {
  animation-delay: 0.075s;
}

.d-home-table tbody :nth-of-type(7) {
  animation-delay: 0.09s;
}

.d-home-table tbody :nth-of-type(8) {
  animation-delay: 0.105s;
}

.d-home-table tbody :nth-of-type(9) {
  animation-delay: 0.12s;
}
.d-home-table tbody :nth-of-type(10) {
  animation-delay: 0.135s;
}
.d-home-table tbody :nth-of-type(n + 11) {
  animation-delay: 0.15s;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  body .home-table-flex .custom-table .table-bg {
    height: auto !important;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .d-hero-col {
    width: 100%;
  }

  .d-table-col {
    width: 100%;
  }

  .d-point-bar {
    padding: 21px 30px;
    max-width: 100%;
  }

  .points-progress-bar {
    height: 1rem;
  }

  .d-hero-col .d-home-table.d-card {
    justify-content: space-between;
    overflow: hidden;
  }
  .custom-table table tbody tr td {
    padding-right: 10px !important;
  }

  .custom-table table thead tr th {
    padding: 20px 11px !important;
  }

  .home-table-flex {
    flex-direction: column;
  }

  .d-hero-icon:before {
    width: 100%;
  }

  .d-hero-col .d-home-table.d-card .d-top-bar {
    flex-direction: row;
  }

  body .d-hero-icon {
    margin: 30px 0;
    width: 100%;
  }

  .total-count .d-gradient-text {
    font-size: 30px !important;
  }

  .total-count .d-gradient-text span {
    font-size: 20px !important;
  }

  .total-count img {
    max-width: 37px;
  }
  .d-table-col .d-home-table {
    height: 100%;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  body .home-table-flex .custom-table .table-bg {
    height: auto !important;
  }
  .d-hero-col {
    width: 100%;
  }

  .d-table-col .d-home-table {
    height: 100%;
  }
  .d-table-col {
    width: 100%;
  }

  .d-point-bar {
    padding: 21px 30px;
    max-width: 75%;
  }

  .points-progress-bar {
    height: 1rem;
  }

  .d-hero-col .d-home-table.d-card {
    justify-content: space-between;
  }

  .home-table-flex {
    flex-direction: column;
  }
  body .d-hero-icon {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
 body .home-table-flex .custom-table .table-bg {
    height: calc(100vh - 260px) !important;
}
  .d-hero-col {
    width: 55%;
  }
  /* .custom-table .table-bg,
  .custom-table table {
    height: 63vh !important;
  } */
  body .d-hero-icon {
    width: 70%;
  }
  .d-point-bar div {
    height: 1rem;
  }
  .total-count .d-gradient-text {
    font-size: 31px !important;
  }
  .total-count .d-gradient-text span {
    font-size: 31px !important;
  }
  .home-table-flex {
    padding: 20px 20px;
  }
  .total-count img {
    max-width: 42px;
  }
  .d-table-col {
    width: 45%;
  }

  .d-point-bar {
    padding: 21px 30px;
    max-width: 75%;
  }

  .points-progress-bar {
    height: 1rem;
  }

  body .d-hero-col .d-home-table.d-card {
    justify-content: space-between;
    overflow: auto;
    height: 81vh;
  }
  .d-table-col .d-home-table {
    height: 80vh;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .d-hero-col {
    width: 60%;
    overflow: hidden;
  }

  .d-table-col {
    width: 40%;
  }
  .total-count .d-gradient-text {
    font-size: 38px !important;
  }
  .total-count .d-gradient-text span {
    font-size: 38px !important;
  }

  .d-home-table .d-top-bar {
    margin: 0;
  }
  .custom-table {
    margin-top: 20px;
  }
  .home-table-flex {
    padding: 20px 20px;
  }
  .d-point-bar {
    padding: 12px 27px;
    max-width: 75%;
  }

  .points-progress-bar {
    height: 1rem;
  }
  body .d-hero-icon {
    width: 390px;
  }
}
