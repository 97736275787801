.d-nav-link {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 12px;
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  width: fit-content;
  border-radius: 20px;
  transition: all 0.5s ease;
  text-transform: uppercase;
  cursor: pointer;
}
.d-nav-link:hover {
  box-shadow: 0 0 10px rgb(238 255 0 / 47%);
  transform: translateY(-5px);
}
