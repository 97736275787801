.download-page {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg-download.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  gap: 15px;
}

.download-page img {
  width: 100%;
  max-width: 30%;
}

.download-page p {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 15px;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 25px;
  /* width: 35%; */
  width: 50%;
}
.download-page .page-heading {
  font-size: 35px;
}
.download-page .h2head {
  text-align: center;
  padding: 0 10px;
}
.download-page .btn-cont {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.download-page .btn-cont a {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.download-page .btn-cont a img {
  width: 18px;
  height: 18px;
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .download-page img {
    width: 100%;
    max-width: 30%;
  }
  .download-page .page-heading {
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .download-page .btn-cont a {
    font-size: 11px;
  }

  .download-page img {
    width: 100%;
    max-width: 35%;
  }
  .download-page .page-heading {
    font-size: 20px;
  }
}

@media screen and (min-width: 550px) and (max-width: 767px) {
  .download-page p {
    width: 90%;
  }
  .download-page .btn-cont {
    flex-direction: column;
  }
  .download-page .btn-cont a {
    font-size: 11px;
  }
  .download-page img {
    width: 100%;
    max-width: 35%;
  }
  .download-page .page-heading {
    font-size: 17px;
  }
}
@media screen and (min-width: 300px) and (max-width: 550px) {
  .download-page p {
    width: 90%;
  }
  .download-page img {
    width: 100%;
    max-width: 80%;
  }
  .download-page .page-heading {
    font-size: 25px;
  }
}
