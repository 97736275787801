.airdropGuide-page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg21.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-attachment: fixed;
}

.airdropGuide-container {
  /* padding: 2.2vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  width: 55vw;
  text-align: center;
}

.airdropGuide-container .airdrop-box {
  background: transparent;
  border: 1px solid #d0a755;
  padding: 2.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  box-sizing: border-box;
  margin: 20px 0;
}

.airdropGuide-container .airdrop-box .account-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.airdropGuide-container .airdrop-box .account-body img.stepBg {
  width: 50%;
}
.account-body .registerBtn img::after {
  content: "Register";
}

.airdropGuide-container .complete-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.airdropGuide-container .complete-container p,
.airdropGuide-container .complete-container span {
  text-align: start;
  font-size: 14px;
}
.airdropGuide-container .complete-container .line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}
.airdropGuide-container .complete-container .line p {
  margin: 0;
}
.airdropGuide-page .disabled {
  background-color: #2c2517;
  cursor: default;
}
/* create acc steps */
.steps-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0px;
  align-items: center;
}
.lines {
  flex: 1;
  position: relative;
  /* width: 50px; */
  height: 2px;
  background-color: #d0a755;
}

.first::before {
  position: absolute;
  content: "";
  left: -10px;
  top: -3px;
  width: 5px;
  height: 5px;
  border: 2px solid #d0a755;
  transform: rotate(45deg);
}

.last::after {
  position: absolute;
  content: "";
  right: -10px;
  top: -3px;
  width: 5px;
  height: 5px;
  border: 2px solid #d0a755;
  transform: rotate(45deg);
}

.icons {
  width: 35px;
  height: 35px;
  opacity: 0.4;
  transition: all 0.1s ease-in-out;
}

.icons.focus {
  transform: scale(1.4);
  opacity: 1;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .airdropGuide-container {
    width: 90vw;
  }
  .airdropGuide-container .airdrop-box .account-body img.stepBg {
    width: 80%;
  }
  .airdropGuide-container .airdrop-box {
    width: 90vw;
  }
  .airdropGuide-page .icons {
    width: 25px;
    height: 25px;
    opacity: 0.4;
    transition: all 0.1s ease-in-out;
  }

  .airdropGuide-page .icons.focus {
    transform: scale(1.4);
    opacity: 1;
  }

  .airdropGuide-container .complete-container p,
  .airdropGuide-container .complete-container span {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .airdropGuide-container .complete-container .line {
    gap: 60px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .airdropGuide-container {
    width: 80vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
}
