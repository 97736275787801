.banner {
  display: flex;
  padding-top: 24px;
}
.banner .detail {
  flex: 1;
  background: linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.banner .detail h4 {
  text-transform: uppercase;
  color: #0f0d0d;
  font-size: 40px;
  letter-spacing: 3px;
}
.banner .detail p {
  color: #0f0d0d;
  font-size: 17px;
}
.banner .banner-img {
  flex: 1;
}
.banner .banner-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .banner {
    flex-direction: column-reverse;
  }
  section.inner-header {
    height: auto;
    padding: 10% 0 10% 0;
  }
  .banner .detail {
   padding: 24px;
  }
  .banner .detail h4 {
    font-size: 28px;
  }
}
