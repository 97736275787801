.d-card {
  position: relative;
  background: #ffffff0f;
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 24px;
  padding: 30px 10px;
  position: relative;
}

.d-bg-secondary-card {
  position: relative;
  overflow: hidden;
  border: none;
  background: linear-gradient(180deg, #0000 40%, #00000061 81%, #000 100%);
}
.d-bg-secondary-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 38px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
  /* content: "";
  position: absolute;
  width: 100%;
  z-index: -1;
  transition: all 0.5s ease;
  opacity: 1;
  border: 29px solid transparent;
  border-image-source: url("https://d2j04pqii01bv9.cloudfront.net/dashboard-images/d-card-fram.svg");
  border-image-slice: 20;
  border-image-repeat: stretch;
  overflow: hidden;
  height: 99%;
  background: transparent;
  box-sizing: border-box;
  left: 0;
  top: 0; */
}

.icon-point div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.icon-point div.dot {
  width: 12px;
  height: 12px;
  border-radius: 70px;
  margin-left: 0;
  margin-right: auto;
}

.icon-point div.active {
  background: #eeff00;
}

.icon-point div.completed {
  background: #66ff00;
}
