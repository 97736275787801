.d-btn {
  width: fit-content;
  height: 52px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  border: none;
  letter-spacing: 0px;
  border-radius: 600px;
  display: flex;
  padding: 0 30px;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease;
  background: transparent;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.d-btn img {
  margin-right: 10px;
}

.d-bg-primary-contained {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  box-shadow: 0 5px 0px #c5620c;
}

.d-bg-primary-contained:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-shadow: 0 30px 30px #c5620cad;
  left: 0;
  border-radius: 110px;
}

.d-bg-primary-contained:focus {
  box-shadow: none;
  transform: translateY(5px);
}

.d-bg-secondary-contained {
  position: relative;
  padding: 20px 25px !important;
  height: auto;
}
.d-bg-secondary-contained:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 45px;
  padding: 1px;
  background: linear-gradient(135deg, #EEFF00 0%, #FFAA00 50.81%, #FF5E00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.d-gradient-text {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.d-bg-secondary-contained:hover {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  box-shadow: 0 5px 0px #c5620c;
  transform: translateY(-10px);
}

.d-bg-secondary-contained:hover .d-gradient-text {
  color: #fff;
}

.d-btn .loader {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #d0a755;
}

.d-bg-secondary-contained.isloading-button {
  width: 167px;
}

.full-width{
  width: 100% !important
}
