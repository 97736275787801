.settings .tbbox {
  display: flex;
  align-items: center;
  justify-content: start;
  align-content: center;
  flex-wrap: wrap;
  padding: 25px;
  border: 1px solid #d0a755;
  margin-bottom: 40px;
  background: rgb(8 18 28 / 75%);
  /* min-height: 300px; */
}

.sideBar-head {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #ede5ce;
  margin: 0;
  transition: all 0.5s ease;
}

.settings .tbbox h5 {
  width: 100%;
  flex: 0 0 100%;
  color: #ede5ce;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}

/* progress bar  */
.settings .profile-progress {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 40px 0;
}
.settings .profile-progress .progress-container {
  background-color: rgb(192, 192, 192);
  border-radius: 15px;
}

.settings .profile-progress .progress {
  background: #d0a755;
  color: white;
  padding: 1.5%;
  text-align: right;
  font-size: 20px;
  border-radius: 15px;
}
.settings .profile-progress .progress-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settings .profile-progress span {
  font-size: 13px;
  color: #b3b3b3;
}
.settings .profile-progress h3 {
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 400;
}
.settings .profile-progress h3.progress-heading {
  color: #ede5ce;
}
.settings .profile-progress h3.progress-percentage {
  color: #d0a755;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .settings .tbbox {
    padding: 20px;
  }

  .settings .tbbox h5 {
    font-size: 20px;
  }
}
