.notification-wrapper .notification-list .loader {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #d0a755;
}

.notification-wrapper .notification-list .load-full {
  width: 100%;
  height: 100% !important;
}
.d-notification-icon span.not-count {
  position: absolute;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  top: -10px;
  right: -10px;
  border-radius: 60px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #191930;
  font-family: "Montserrat";
  font-weight: 800;
}

.notification-wrapper .notification-list li.not-item.read-notification {
  opacity: 0.5;
}


.no-not-data {
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: center;
  align-items: center;
}

/* .notification-wrapper .notification-list ul {
  display: flex;
  flex-direction: column-reverse; 
} */
