.d-setting-lsit {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
  margin: 30px 0 0 0;
  align-items: self-end;
}

.d-setting-lsit .setting-item {
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background: #ffffff05;
  height: 80px;
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
}

.d-setting-lsit .setting-item:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.d-setting-lsit .setting-item .fouteen-size span {
  width: 100%;
  display: inline-block;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #ffffff99;
  text-transform: capitalize;
  margin: 6px 0 0 0;
}

.d-model-text.del-modal span {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin: 0 0 30px 0 !important;
  display: block;
}

.d-model-text.del-modal button.d-bg-secondary-contained.full-contain.d-btn {
  margin-bottom: 20px;
}
.d-top-bar h2 img {
  width: 11px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(378%)
    hue-rotate(74deg) brightness(120%) contrast(100%);
  margin: 0 20px 0 0;
}

.d-top-left.go-back h2 {
  cursor: pointer;
}

.langunage-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 0;
  margin: 30px 0 0 0;
}

.langunage-list .langunage-item {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 10px;
  box-sizing: border-box;
  border-radius: 60px;
  background: rgb(255 255 255 / 2%);
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease;
}
.del-modal button.d-bg-primary-contained.w-full:hover {
  transform: translateY(5px);
  box-shadow: none;
}

.langunage-list .langunage-item:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 60px;
  padding: 1.5px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.langunage-list .langunage-item .user-item {
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 700;
  color: rgb(255 255 255 / 60%);
}
.langunage-list .langunage-item.active {
  background: rgb(255 255 255 / 10%);
  background-size: 100% 100%;
}

.langunage-list .langunage-item:hover {
  background: rgb(255 255 255 / 10%);
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .d-setting-lsit .setting-item {
    width: 100%;
  }
  .langunage-list .langunage-item {
    padding: 13px 20px;
    width: 100%;
  }
  .d-setting-lsit {
    flex-direction: column;
  }
  .langunage-list .langunage-item .user-item img.user-img {
    width: 20px !important;
    height: 20px;
    max-width: 20px;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .langunage-list .langunage-item {
    padding: 8px 7px;
  }
  .langunage-list .langunage-item .user-item img.user-img {
    width: 20px !important;
    height: 20px;
    max-width: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .langunage-list .langunage-item {
    padding: 5px 7px;
  }

  .langunage-list .langunage-item .user-item {
    font-size: 10px;
  }

  .langunage-list .langunage-item img {
    width: 100%;
    max-width: 12px;
    margin: 0 10px 0 0px;
  }
  .langunage-list .langunage-item .user-item img.user-img {
    width: 20px !important;
    height: 20px;
    max-width: 20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .langunage-list .langunage-item {
    padding: 9px 10px;
  }

  .langunage-list .langunage-item .user-item .user-img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .langunage-list .langunage-item {
    padding: 12px 11px;
  }
  .langunage-list .langunage-item .user-item img.user-img {
    width: 20px;
    height: 20px;
  }
}
