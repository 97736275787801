.scores-table {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 40px 0;
  align-items: flex-start;
}

.custom-table table tbody tr:last-child td {
  border: none;
}

/* .custom-table .pagination button:hover,
.custom-table .pagination button:focus {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.custom-table .pagination button:hover img,
.custom-table .pagination button:focus img {
  filter: none;
} */
.custom-table table tbody tr:hover {
  background: #ffffff14;
  cursor: pointer;
}

.custom-table table tbody tr:hover td,
.custom-table table tbody tr:hover td div {
  background: linear-gradient(180deg, #ef0, #fa0 51%, #ff5e00);
  -webkit-background-clip: text;
  background-clip: text;
  color: #0000;
}

.custom-table table tbody td.no-data a.d-gradient-text.d-nav-link {
  margin: 20px auto;
}
.table-list li {
  display: flex;
  justify-content: space-between;
  padding: 12px 21px;
  border-bottom: 1px solid #ffffff1f;
}

.table-list ul {
  background: #ffffff0a;
  padding: 10px 0;
  border-radius: 16px;
}

.table-list li:last-child {
  border: none;
}

.high-modal h3 {
  text-align: start;
}

.high-modal .d-model-content .d-model-main .table-list ul {
  height: 400px;
  overflow-y: auto;
  margin: 20px 0 0 0;
}

.high-modal .d-model-content .d-model-main .table-list ul::-webkit-scrollbar {
  width: 1px;
}
.high-modal .d-model-content {
  justify-content: space-around;
}

.d-modal-img-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.d-modal-img {
  width: 40%;
  max-width: 30px;
  height: 30px;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
}
.table-list li span:last-child {
  color: #fff;
}
.table-list .load-full {
  height: 100% !important;
  width: 100% !important;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .d-search-box {
    width: 100% !important;
  }
  .scores-table > img {
    display: none;
  }
  .d-search-box {
    width: 350px;
    margin-top: 20px;
  }

  .d-search-box span.search-btn {
    width: 80px !important;
  }

  .custom-table table {
    width: 99%;
    overflow: auto;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .scores-table > img {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .scores-table img {
    width: 11%;
  }
  .d-search-box {
    width: 350px;
  }
  .d-search-box span.search-btn {
    width: 20%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
}
