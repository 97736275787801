.about-page {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/about-hero-bg.png);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.about-page p.paraStyle {
  margin: 0 auto;
}
.about-page:before {
  content: "";
  position: absolute;
  width: 500px;
  height: 100%;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/about-banner-hero.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  left: 0;
  top: 0;
}
.about-page .container {
  position: relative;
  z-index: 8;
}
.about-page:after {
  content: "";
  position: absolute;
  width: 500px;
  height: 100%;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/about-banner-hero-two.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  right: 0;
  bottom: 0;
}

.about-abilities img {
  width: 50%;
}
.paraStyle {
  width: 50%;
  font-size: 17px;
  font-family: "Roboto";
  font-weight: 400;
  color: #b3b3b3;
  margin: 0;
}
.screen-video video {
  border-radius: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-map-info .col-7 {
  width: 60%;
}

.about-map-info .col-5 {
  width: 40%;
  padding: 0px 50px 0 0;
  box-sizing: border-box;
}

.about-map-info .col-7 img {
  max-width: 100%;
}

.about-map-info .row {
  align-items: center;
}

.about-map-info .col-5 p {
  padding: 0 0;
}

.abt-info-content {
  padding: 5% 0;
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
}

.abt-info-content h2.h2head.mb5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(94.19deg, #0f0d0d 2.13%, #4e4949 92.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
}

.abt-info-content p {
  color: #0f0d0d;
  font-size: 17px;
  font-weight: 400;
  font-family: "Roboto";
  width: 32%;
  margin: 0 auto;
}

.heros-videos .col-7 {
  width: 58%;
}

.heros-videos .col-5 {
  width: 40%;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}

.heros-videos .play-video {
  width: 100%;
  height: 680px;
  padding: 49px 26px;
}

.footer-img img {
  width: 100%;
}
.heros-videos .play-video video {
  border-radius: 57px;
}

.playwin.heros-videos .col-5 {
  padding: 0 0 0 40px;
}

.heros-videos .row {
  align-items: center;
}

.ChooseHeor.about-heros {
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/about-choose-bg.png);
  padding: 22% 0;
  background-position: center;
}

.ChooseHeor.about-heros .row {
  align-items: center;
}
.about-abilities {
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/abilities-bg.png);
  background-size: cover;
  background-position: center;
  padding: 10% 0;
  text-align: center;
}
.ChooseHeor.about-heros .row {
  align-items: center;
}

.about-abilities p {
  width: 70%;
  margin: 20px auto;
  font-size: 15px;
  line-height: 20px;
}

.PlayReverse .row h2.h2head.mb5 {
  font-size: 84px;
  padding: 0 30px 0 0;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .abt-info-content p {
    width: 100%;
  }

  .abt-info-content {
    padding: 10% 20px;
  }

  .about-map-info .col-5,
  .about-map-info .col-7 {
    width: 100%;
    padding: 0;
  }

  section.screen-video {
    height: auto !important;
  }

  section.about-page {
    height: 500px;
  }

  .about-page:before {
    width: 180px;
    background-position: top left;
  }

  .about-page:after {
    width: 180px;
  }

  .about-page p.paraStyle {
    width: 100%;
  }
  .heros-videos .col-7,
  .heros-videos .col-5 {
    width: 100%;
  }

  .playwin.heros-videos .col-5 {
    padding: 0;
  }

  .PlayReverse.heros-videos .row {
    flex-direction: column;
  }
  .heros-videos .play-video {
    height: 210px;
    padding: 10px 8px;
    width: 310px;
  }

  .heros-videos .play-video video {
    border-radius: 26px;
  }
  .about-abilities img {
    width: 100%;
  }
  .PlayReverse .row h2.h2head.mb5 {
    font-size: 40px;
}
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .heros-videos .play-video {
    width: 380px;
    height: 340px;
    padding: 50px 13px;
  }

  .heros-videos .play-video video {
    border-radius: 20px;
  }
  section.screen-video {
    height: 300px !important;
  }

  .abt-info-content p {
    width: 100%;
  }

  .abt-info-content {
    padding: 10% 0;
  }

  .about-map-info .col-5,
  .about-map-info .col-7 {
    width: 100%;
    padding: 0;
  }

  section.screen-video {
    height: 430px !important;
  }

  section.about-page {
    height: 500px;
  }

  .about-page:before {
    width: 250px;
    background-position: top left;
  }

  .about-page:after {
    width: 250px;
  }

  .about-page p.paraStyle {
    width: 100%;
  }

  .playwin.heros-videos .col-5 {
    padding: 0;
  }
  .PlayReverse .row h2.h2head.mb5 {
    font-size: 40px;
}
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .heros-videos .play-video {
    height: 400px;
    padding: 15px 19px;
    width: 550px;
  }
  .heros-videos .play-video video {
    border-radius: 48px;
  }
  .about-page:before,
  .about-page:after {
    width: 370px;
  }

  section.about-page {
    height: 700px;
  }

  section.screen-video {
    height: 400px !important;
  }

  .abt-info-content p {
    width: 70%;
  }

  .ChooseHeor.about-heros {
    background-size: cover;
    padding: 10% 0;
  }
  .PlayReverse .row .col-7 {
    width: 55%;
  }

  .PlayReverse .row .col-5 {
    width: 45%;
    padding: 0 60px 0 0;
  }

  .PlayReverse .row h2.h2head.mb5 {
    font-size: 45px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .heros-videos .play-video {
    height: 590px;
    padding: 49px 19px;
    width: 770px;
  }
  .PlayReverse .row h2.h2head.mb5 {
    font-size: 64px;
  }
}
@media screen and (min-width: 1601px) and (max-width: 1850px) {
}
