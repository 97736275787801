footer.sub-footer {
  text-align: center;
  padding: 11px 0;
}
.heros-banner {
  /* background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/join-heros-banner.png); */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow: hidden;
}
section.heros-banner video {
  border-radius: 0 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}
.heros-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000091;
  z-index: 1;
  left: 0;
  top: 0;
}
.heros-banner .container {
  position: relative;
  z-index: 1;
}
footer.sub-footer {
  text-align: center;
  padding: 11px 0;
}

.heros-banner h2.h2head {
  font-size: 50px;
}

.heros-banner p.paraStyle {
  text-align: center;
  display: block;
  margin: 7px auto;
}

.heros-banner a.btn {
  display: block;
  width: fit-content;
  margin: 20px auto;
}

.heros-banner a.log-btn {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.heros-featured {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/marketplace.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 3% 0;
}

.heros-featured .row {
  align-items: flex-start;
}

.heros-featured .row .col-5 {
  box-sizing: border-box;
  display: block;
  margin: 0 30px 0 0;
}

.heros-featured .row .col-7 {
  margin: 0 -30px 0 0 !important;
}

.heros-featured .row .col-7 img {
  width: 100%;
  margin: 0 0 0 30px;
}

ul.high-lights {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 70px;
}

ul.high-lights li {
  display: flex;
  width: 47%;
  gap: 20px;
  align-items: self-start;
  margin-bottom: 25px;
}

ul.high-lights li p {
  margin: 0;
  line-height: 27px;
  font-size: 15px;
}
ul.high-lights li h3,
ul.high-lights li p {
  margin-top: 8px;
}
.heros-featured .abt-info-content a.btn {
  display: inline-block;
  margin: 20px auto 0px auto;
  background: #191c1f;
  color: #ede5ce;
  padding: 15px 30px;
  font-size: 20px;
}

.heros-featured .abt-info-content {
  padding: 1% 0 2% 0;
  margin-top: 40px;
}

.heros-featured .abt-info-content h3.tabhead {
  color: #0f0d0d;
  margin: -14px 0 10px 0;
}
.sing-up-high {
  padding: 5% 0;
}

.reward-heros {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/about-map-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 5% 0;
  text-align: center;
}

.reward-heros .hero-imges img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reward-heros .hero-imges {
  height: 390px;
  border-radius: 40px;
  width: 90% !important;
  margin: 0 auto;
  overflow: hidden;
}

.reward-heros .slick-track {
  padding: 14% 0 3% 0;
  transition-timing-function: linear !important;
}

.reward-heros .slick-track .slick-slide:nth-child(odd) {
  margin-top: -180px;
}
.heros-videos .col-7 img {
  max-width: 100%;
}

.mt-4 {
  margin-top: 32px;
}

.bg-img {
  padding: 10% 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ways-wrapper,
.matrices-wrapper,
.matrices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  flex-wrap: wrap;
}
.ways-wrapper {
  align-items: stretch;
}
.matrices-wrapper {
  align-items: flex-start;
}

.matrices-container,
.video-container {
  flex: 1;
  flex-wrap: wrap;
}
.video-container {
  margin: 0 auto;
  text-align: center;
}
.w-50 {
  width: 48%;
}

.w-30 {
  width: 32%;
}

.ways-Item {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: 8px;
  justify-content: space-between;
}

.ways-Item h4 {
  font-family: "Rakkas";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ede5ce;
}

.ways-Item p,
p.description {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1px;
  color: #b3b3b3;
}
.dash-bod-pic {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 0;
  box-sizing: border-box;
}
.dash-bod-pic img {
  margin: 0 !important;
}

.heros-featured .col-7 {
  text-align: center;
}

.matrices-container .ways-Item img {
  width: 40%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .telegram-sec h2.h2head {
    font-size: 37px;
  }
  .dash-bod-pic {
    width: 100%;
    margin-left: auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  body .dash-bod-pic img {
    width: 100% !important;
    max-width: 100% !important;
  }
  .ways-wrapper,
  .matrices-wrapper {
    flex-direction: column;
  }
  .w-50 {
    width: 46%;
  }
  .w-30 {
    width: 100%;
  }
  .sub-sidebar ul.nav li {
    margin: 25px 0 !important;
  }

  .sub-sidebar {
    padding: 50px 20px;
    box-sizing: border-box;
  }

  .sub-sidebar ul.nav li a {
    width: 100%;
    text-align: start;
    display: flex;
    gap: 10px;
  }

  .sub-sidebar ul.nav li a img {
    width: 100%;
    max-width: 28px;
  }

  .sub-sidebar a.btn {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    padding: 15px 0;
  }

  .sub-sidebar a.log-btn {
    text-align: center;
    font-size: 20px;
    padding: 15px 0;
  }
  .sub-header ul.socil {
    display: none;
  }

  section.heros-banner {
    height: auto;
    padding: 20% 0;
  }

  section.heros-banner img {
    width: 80%;
  }

  .heros-banner h2.h2head {
    font-size: 40px;
    margin: 20px 0;
  }

  .heros-banner p.paraStyle {
    width: 100%;
    padding: 10px 0;
    line-height: 31px;
  }

  .heros-banner a.btn {
    font-size: 21px;
  }

  .heros-banner a.log-btn {
    font-size: 20px;
  }

  .heros-videos .row {
    flex-direction: column-reverse !important;
  }

  .heros-videos .row .play-content {
    padding-top: 0;
  }

  .heros-featured .row .col-7 img {
    margin: 0;
  }

  .heros-featured .row .col-7 {
    width: 100%;
  }

  .heros-featured .row .col-5 {
    margin: 0;
    padding: 0 20px;
  }

  ul.high-lights {
    width: 100%;
    margin-top: 16px;
  }

  .heros-featured .abt-info-content h3.tabhead {
    margin: 0 0 10px 0;
  }

  .heros-featured .abt-info-content {
    padding: 4% 20px 11% 20px;
  }
  .sing-up-high .play-content {
    padding-bottom: 50px;
  }

  .reward-heros .hero-imges {
    height: 250px;
  }

  .reward-heros .slick-track .slick-slide:nth-child(odd) {
    margin-top: -90px;
  }

  .reward-heros .slick-track {
    padding: 39% 0 9% 0;
  }

  footer.sub-footer {
    text-align: start;
    padding: 15px 20px;
  }

  footer.sub-footer p {
    font-size: 12px;
  }
  .telegram-sec .row {
    flex-direction: column;
  }

  .telegram-sec .row .col-5,
  .telegram-sec .row .col-7 {
    width: 100%;
  }

  .dash-bod-pic {
    width: 100%;
  }

  .telegram-sec .row .col-5 .dash-bod-pic {
    margin-bottom: 50px;
  }

  ul.high-lights li {
    width: 100%;
  }

  .telegram-sec .ways-Item.w-50 {
    width: 100%;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sub-header .leftdiv {
    flex: 0 0 50%;
  }
  .telegram-sec .row {
    flex-direction: column;
  }

  .telegram-sec .row .col-5,
  .telegram-sec .row .col-7 {
    width: 100%;
  }

  .dash-bod-pic {
    width: 100%;
  }

  .telegram-sec .row .col-5 .dash-bod-pic {
    width: 50%;
    margin-bottom: 50px;
  }
  .sub-header .leftdiv > a {
    width: 60%;
  }
  section.heros-banner {
    height: auto;
    padding: 20% 0;
  }

  section.heros-banner img {
    width: 100%;
    max-width: 70%;
    margin-bottom: 20px;
  }

  section.heros-banner p.paraStyle {
    width: 80%;
    padding: 10px 0;
  }

  .heros-featured .row .col-7 {
    margin: 0 !important;
    width: 50%;
  }

  .heros-featured .row .col-5 {
    width: 50%;
    margin: 0;
  }

  ul.high-lights {
    width: 100%;
  }

  .reward-heros h2,
  .reward-heros h3,
  .reward-heros p {
    padding: 0 70px;
  }

  ul.high-lights li p {
    font-size: 13px;
    line-height: 27px;
  }
  .reward-heros .hero-imges {
    height: 280px;
  }

  .reward-heros .slick-track {
    padding: 26% 0 3% 0;
  }
  .w-50 {
    width: 46%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .w-50 {
    width: 46%;
  }
  section.heros-banner {
    height: auto;
    padding: 15% 0;
  }
  .sub-header .leftdiv {
    flex: 0 0 50%;
  }

  .sub-header .leftdiv > a {
    width: 60%;
  }
  section.heros-banner img {
    width: 50%;
  }

  ul.high-lights {
    width: 100%;
  }

  .reward-heros .hero-imges {
    height: 280px;
  }

  .reward-heros .slick-track {
    padding: 21% 0 4% 0;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  /* section.heros-banner {
    height: 75vh;
  } */

  .reward-heros .hero-imges {
    height: 300px;
  }
}
