.airdrop-banner {
  display: flex;
  align-items: center;
  background: linear-gradient(
    180deg,
    #ffffff05 60%,
    #eeff002e 100%,
    #ffaa00 80%,
    #ff5e00 101%
  );
  padding: 20px;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  justify-content: space-between;
  margin: 30px 0 40px 0;
}

.airdrop-banner div:first-child {
  width: 40%;
}

.airdrop-banner div:last-child {
  width: 20%;
  text-align: end;
}

.airdrop-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
  bottom: 0;
  left: 0;
}

.airdrop-banner div img {
  width: 50%;
}

.airdrop-banner h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -1px;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}

.airdrop-banner p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #ffffff99;
}

.d-model-content .d-model-text .input-field {
  border-radius: 16px;
}

.d-model.active-model.p-0 .d-model-main {
  padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 767px) {

  .friend-list .friend-list-item {
    width: 100%;
  }

  .freind-invite-bar {
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  }

  .freind-invite-bar .input-field {
    width: 100%;
    box-sizing: border-box;
  }

  .freind-invite-bar button.d-bg-primary-contained.d-btn {
    width: 100%;
  }

  .airdrop-banner {
    flex-direction: column-reverse;
  }

  .airdrop-banner div:first-child,
  .airdrop-banner div:last-child {
    width: 100%;
  }

  .airdrop-banner div:last-child {
    text-align: center;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .airdrop-banner div:first-child {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .airdrop-banner div:first-child {
    width: 70%;
}
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
}
