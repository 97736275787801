.mb2 {
  margin-bottom: 20px;
}
.d-model-text .light-btn {
  opacity: 0.5;
}

.d-model-text .light-btn:hover {
  opacity: 1;
}

.d-model-content .d-model-text .icon-point {
  justify-content: center;
  margin-bottom: 30px;
}

.d-model-content .d-model-text h4 {
  font-size: 20px;
  color: #fff;
  font-family: "Gilroy";
}
.d-model-content .d-model-text p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff99;
}
.d-model-content .d-model-text p span {
  font-weight: 500;
  color: #fff;
}

.heros-image-slider .d-image-slider-card img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.heros-image-slider .swiper-slide.swiper-slide-active {
  width: 220px !important;
  z-index: 999;
  transition: all 0.5s ease;
  height: 85%;
}

.heros-image-slider .swiper-slide.swiper-slide-prev {
  margin-left: -65px;
}

.heros-image-slider .swiper-slide {
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.heros-image-slider .swiper-wrapper {
  height: 350px;
}

.heros-image-slider {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.heros-image-slider .d-image-slider-card.d-card {
  padding: 0px;
  overflow: hidden;
  border-radius: 22px;
  width: 210px;
  height: 210px;
}
.heros-image-slider
  .swiper-slide.swiper-slide-active
  .d-image-slider-card.d-card {
  height: 100%;
}

.heros-image-slider
  .swiper-slide.swiper-slide-active
  .d-image-slider-card.d-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 22px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 1;
  z-index: 0;
}

.heros-image-slider .d-image-slider-card.d-card::before {
  opacity: 0;
}

.heros-image-slider span.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #ffffff66;
  position: relative;
  margin: 0 8px !important;
}

.heros-image-slider .swiper-pagination-bullet-active {
  background: #fff !important;
}

.heros-image-slider .swiper-pagination-bullet-active:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.d-model-content .d-model-text .icon-point img {
  width: 100%;
  max-width: 30px;
}
.swiper-button-next,
.swiper-button-prev {
  background: #25282d;
  width: 44px !important;
  height: 44px !important;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ffaa00;
  border-radius: 6px;
  top: 43% !important;
}
.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  background: linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.heros-image-slider.selected-hero .d-image-slider-card {
  height: 300px;
  margin: 0 auto;
}

.heros-image-slider.selected-hero .d-image-slider-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffaa00 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 1;
  z-index: 0;
}

.heros-image-slider.selected-hero {
  margin: 30px 0;
}

.heros-image-slider.selected-hero h1 {
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  color: #ffffff;
}

.d-model-content .d-model-text.task-model h3 {
  max-width: 315px;
  margin: auto;
}