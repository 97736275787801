.d-earnings-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 0px 20px 20px 0;
  margin-top: 20px;
}

.d-earnings-list .earning-card {
  min-height: 230px;
  border-radius: 37px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.d-earnings-list .earning-card h2.d-gradient-text.d-tit {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: -1px;
  text-align: start;
  margin: 10px 0;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
}
.d-earnings-list .earning-card h2.d-gradient-text.d-tit img {
  width: 100%;
  max-width: 50px;
}

.d-earnings-list .earning-card .icon-point {
  justify-content: space-between;
  align-items: center;
}

.d-earnings-list .earning-card h3.hrad-text {
  height: auto;
  padding: 0;
  text-align: start;
}

.d-earnings-list .earning-card p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffff99;
  padding: 0 20px;
}

.info-tool-tip {
  position: absolute;
  width: 100%;
  padding: 11px 10px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  max-width: 160px;
  top: 28px;
  right: 0;
  border: 1px solid #ffffff33;
  border-radius: 16px;
  opacity: 0;
  transition: fade 0.5s ease;
  background: #ffffff0a;
}

.info-tool-tip p {
  padding: 0 !important;
  margin: 0;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  text-align: left !important;
}
.icon-point .ques {
  cursor: pointer;
  position: relative;
  z-index: 99;
}
.icon-point .ques:hover ~ .info-tool-tip {
  opacity: 1;
}
.notification-wrapper .notification-list li.not-item .d-notification-icon {
  transition: none;
}

.d-earnings-list .earning-card .d-task-content {
  justify-content: start;
  align-items: start;
  height: auto;
}

.d-earnings-list .earning-card p {
  text-align: justify;
  padding: 0 0 0 0;
}

.airdrop-banner.combat-ban div:first-child {
  width: 60%;
}
.airdrop-banner.combat-ban span.d-gradient-text {
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .d-earnings-list {
    grid-template-columns: repeat(1, 1fr);
    padding-right: 0;
  }
  .d-earnings-list .earning-card {
    border-radius: 36px;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .d-earnings-list {
    grid-template-columns: repeat(2, 1fr);
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .d-earnings-list .earning-card {
    border-radius: 36px;
  }
  .d-earnings-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .d-earnings-list .earning-card {
    border-radius: 34px;
  }
}
