.d-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-top-bar h2 {
  font-family: "poppins";
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
}
.d-task-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 0px 20px 20px 0;
  margin-top: 20px;
}
.d-task-card {
  border-radius: 38px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 294px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 20px 10px 20px !important;
  min-width: 178px;
  z-index: 9;
  width: 100%;
  height: 100%;
  position: relative;
}

.d-top-bar .d-top-right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.d-fit-w {
  min-width: fit-content;
  padding: 5px 10px;
}
.d-top-bar .d-top-left p {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 24px;
  color: #ffffff99;
  margin: 10px 0 10px 0;
}
.custom-table table tbody .no-data td a.d-gradient-text.d-nav-link {
  margin: 20px auto;
}

.custom-table table tbody .no-data td span.d-gradient-text.d-nav-link {
  margin: 20px auto;
}

.d-model-content .wel-modal .flex {
  justify-content: space-between;
  margin-bottom: 20px;
}

.d-model-content .wel-modal h5 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #fff;
  width: 50%;
}

.d-model-text.wel-modal h3 {
  padding: 0 80px;
}
.d-model-content .wel-modal button {
  width: 60%;
  padding: 0 0 !important;
  height: 60px;
}

.d-model-content .wel-modal button.d-bg-secondary-contained {
  background-size: cover;
  height: 63px;
}

.d-model-content .d-model-text .d-icon {
  margin: 30px auto;
}
.success-check-icon {
  width: 100%;
  max-width: 20px;
  margin: 20px 0;
}
/* .d-task-content span.d-gradient-text.d-nav-link {
  margin: 12px 0;
} */
.daily-modal h3 {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .d-top-bar {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 10px;
  }
  body .d-task-card .d-nav-link {
    white-space: break-spaces;
    margin: 20px 0;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .d-content .d-task-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .d-task-card {
    min-height: 230px !important;
  }

  .d-task-card h3.hrad-text {
    height: auto !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .d-task-list {
    grid-template-columns: repeat(3, 1fr);
  }
  .d-task-card {
    min-height: 160px;
  }

  .d-task-card h3.hrad-text {
    height: auto !important;
  }

  .success-check-icon {
    margin: 0px 0 20px 0 !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .d-task-card {
    min-height: 280px;
  }
  .d-task-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .d-task-card .d-task-content h3 {
    padding: 0 17px !important;
    font-size: 12px;
  }
  .flex-head {
    width: fit-content !important;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .d-task-card .d-task-content h3 {
    padding: 0 10px !important;
    font-size: 14px;
    height: 60px !important;
  }
  body .d-task-card {
    min-height: 300px;
    min-width: 100%;
  }
  .d-task-content {
    justify-content: space-evenly;
    height: 110px;
  }
  .d-task-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
