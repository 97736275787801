.single-post {
  padding: 7% 0;
}

.single-post .backbtn {
  border: 1px solid #d0a755;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.single-post .backbtn img {
  margin: 0;
}

.single-post-wrapper {
  display: flex;
  gap: 32px;
}

.post-details {
  flex: 3;
}
.popular-posts {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popular-posts .post-item {
  width: 100%;
  margin-bottom: 16px;
}

.popoular-post-title {
  color: #ede5ce;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2px;
}
.single-post .date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #b3b3b3;
  margin-top: -8px;
}

.content-wrapper {
  margin-top: 32px;
  font-family: "Roboto";
  letter-spacing: 1px;
  padding-right: 10%;
}

.content-wrapper img {
  width: 70%;
  margin: 0 auto;
}

.content-wrapper ul {
  list-style: disc;
  padding-left: 16px;
  margin-block: 32px;
}
.content-wrapper li {
  margin-bottom: 16px;
}

body .singlehead {
  font-size: 50px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 20px 0;
  color: #d0a755;
}

.single-post-content .post-img {
  width: 100%;
  margin: 60px 0;
  height: 550px;
}

.single-post-content .post-img img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: contain;
}

.single-post-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  text-align: justify;
  color: #b3b3b3;
  margin: 30px auto;
}

@media screen and (max-width: 768px) {
  .single-post-wrapper {
    flex-direction: column;
  }
}
