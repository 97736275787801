@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Roboto", "Montserrat", "Poppins", sans-serif;
  background: #08121c;
  color: #b3b3b3;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Rakkas", cursive;
  margin: 0;
}

.mb-10{
  margin-bottom: 10px;
}

.container {
  width: 1550px;
  margin: auto;
  box-sizing: border-box;
}
.fixed-wrap {
  height: 100vh;
  overflow: hidden;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 2% 1% 2%;
  background: rgba(10, 10, 15, 0.6);
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 9;
}

.head .leftdiv {
  display: flex;
  align-items: center;
}

ul.nav {
  display: flex;
  align-items: center;
  list-style: none;
  color: #ede5ce;
}

ul.nav a,
ul.nav li > span {
  font-size: 13px;
  text-decoration: none;
  color: #ede5ce;
  text-wrap: nowrap;
  letter-spacing: 0.1em;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

ul.nav a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  display: block;
  left: 50%;
  bottom: -24px;
  border-radius: 6px;
  background: #d0a755; /* Line color */
  transition: width 0.3s ease, left 0.3s ease;
}

ul.nav a:hover::after {
  width: 100%;
  left: 0;
}

.rightdiv {
  display: flex;
  align-items: center;
  gap: 15px;
}
.log-btn {
  color: #d0a755;
  font-size: 15px;
  background: transparent;
  border-radius: 6px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  border: 1px solid #d0a655;
}
a.log-btn {
  color: #d0a755;
  font-size: 15px;
  background: #191c1f;
  border-radius: 6px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  border: 1px solid #d0a655;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.head .leftdiv ul.nav {
  margin-right: 50px;
}

.rightdiv ul.nav {
  background: #191c1f;
  border-radius: 14px;
  padding: 8px;
  transition: all 0.3s ease;
}

.btn {
  color: #0f0d0d;
  font-size: 14px;
  background: #d0a755;
  border-radius: 6px;
  padding: 10px 20px;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

@media (hover: hover) {
  .btn:hover {
    background: #e3ca99;
  }
}

.rightdiv ul.nav:hover {
  color: #8f8b7f;
  background: #0f1316;
}

.rightdiv ul.nav:hover a {
  color: #8f8b7f;
}

ul.nav a:hover {
  color: #d0a755;
  background-color: rgba(128, 128, 128, 0.6);
  padding: 8px 16px;
  border-radius: 6px;
}
.dropdown-menu li a:hover {
  color: #d0a755 !important;
}

ul.socil {
  margin-left: auto;
  display: flex;
}

p {
  font-size: 13px;
  color: #b3b3b3;
}

footer {
  background: #08121c;
  padding: 30px 0;
  box-sizing: border-box;
}

ul.socil li {
  margin-left: 25px;
}

ul.socil li a img {
  transition: all 0.3s ease;
  transform: scale(1);
}

ul.socil li a:hover img {
  transform: scale(1.2);
}

.Heros-page,
.Posts-page,
.Post-single {
  background: #000;
}

footer .container p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #b3b3b3;
  margin: 0;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
}

.footer-menu .menu-box {
  width: 25%;
  padding: 0 7% 0 0;
  box-sizing: border-box;
}

.footer-menu .menu-box h4 {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #b3b3b3;
}

.footer-menu .menu-box ul a {
  display: flex;
  align-items: flex-start;
  gap: 9px;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #b3b3b3;
  text-decoration: none;
}

.footer-menu .menu-box ul li {
  margin-bottom: 20px;
}

.footer-menu .menu-box ul a img {
  width: 30px;
}

.footer-menu .menu-box:last-child {
  padding: 0;
}

.footer-menu .menu-box ul.socil li {
  margin: 0 13px 0 0;
}

ul.socil li img {
  width: 30px;
  height: 25px;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(73%) sepia(0%) saturate(2%)
    hue-rotate(24deg) brightness(98%) contrast(93%);
}

ul.socil li:hover img {
  filter: brightness(6);
}

.foot-sub {
  display: flex;
  padding: 45px 0;
  border-top: 1px solid #26282a;
  border-bottom: 1px solid #26282a;
  margin: 30px 0;
  align-items: center;
  justify-content: space-between;
}

.foot-sub h3.tabhead {
  margin-bottom: 16px;
}

.foot-sub form {
  position: relative;
  display: flex;
  gap: 20px;
}

.foot-sub form input[type="text"] {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
  border: 1px solid #b3b3b3;
  background: #181c1f;
  color: #ede5ce;
  padding: 10px 10px;
  box-sizing: border-box;
  outline: none;
}

.foot-sub form input[type="text"]::placeholder {
  color: #ede5ce3b;
}

.foot-sub form input[type="submit"] {
  border-radius: 14px;
  background: #d0a755;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1.5px;
  color: #0f0d0d;
  border: none;
  padding: 8px 24px;
  text-transform: uppercase;
  cursor: pointer;
}
.lefttext {
  width: 50%;
  flex: 0 0 50%;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  padding: 10px 0 0 0;
}

.copyright a {
  display: table;
  width: 70%;
  margin-bottom: 20px;
}
.copyright a img {
  width: 100%;
}

footer {
  padding: 80px 0;
}

.footer-menu .menu-box ul a:hover {
  color: #d0a755;
}
.privacy-content {
  padding: 10% 0 0;
}
.privacy-content-list ul li {
  padding-left: 21px;
  font-size: 13px;
  color: #ede5ce;
  padding-bottom: 5px;
}

.privacy-content-list ul li:last-child {
  padding-bottom: 30px;
}
.privacy-content-list a {
  color: #ede5ce;
}

ul.nav li .sub-menu {
  position: absolute;
  padding: 21px 22px;
  background: #020509cc;
  box-sizing: border-box;
  top: 30px;
}

ul.nav li span {
  display: flex;
  width: 100%;
  gap: 20px;
  cursor: pointer;
}

ul.nav li .sub-menu li {
  margin-right: 0;
  margin-bottom: 21px;
  display: block;
}

ul.nav li .sub-menu li:last-child {
  margin: 0;
}

ul.nav li {
  position: relative;
}

ul.nav li span.active img {
  transform: rotate(-180deg);
}

.chat-model {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 24%;
  padding: 30px;
  background: #08121c9e;
  backdrop-filter: blur(5px);
  border: 1px solid #d0a755;
  opacity: 0;
  display: none;
  z-index: 100;
}

.chat-model h2.h2head {
  font-size: 24px;
  text-align: center;
}

.chat-model div {
  display: flex;
  gap: 20px;
  padding: 20px 0;
}

.chat-model div span {
  font-size: 20px;
  color: #b3b3b3;
  line-height: 30px;
}

.chat-model ul {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.chat-model ul button {
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto";
  color: #d0a755;
  text-transform: uppercase;
  cursor: pointer;
}

.chat-model ul a.btn {
  font-size: 20px;
}

.chat-model.active {
  opacity: 100%;
  transition: opacity 1s;
  display: block;
}

.acc-btn {
  color: #d0a755;
  font-size: 14px;
  background: #191c1f;
  border-radius: 6px;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  border: 1px solid #d0a655;
}

.acc-btn:hover,
.acc-btn:focus {
  background: #e3ca99;
  color: #0f0d0d;
}

.acc-btn-links {
  color: "#fff";
  text-transform: "uppercase";
}

/* settings main css  */
.settingsmain {
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/mediabg.png),
    #020509;
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: top;
  padding: 8% 0;
  background-attachment: fixed;
  min-height: 80vh;
}

.col2 {
  width: 38%;
  flex: 0 0 38%;
  position: sticky;
  top: 0;
  left: 0;
}
.col4 {
  width: 58%;
  flex: 0 0 58%;
  margin-left: 4%;
}
.settingsmain .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Wallet Modal  */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}
.modal.open {
  display: block;
}

/* Modal Content */
.modal-content {
  background-color: #08121cbf;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 20%;
  position: relative;
  border: 1px solid #d0a755;
}
.modal-content h2.h2head {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
}
.modal-content .modal-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px 10px 10px 10px;
}
.modal-content .modal-list .modal-item {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 48%;
  gap: 8px;
  background: #191c1f;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
}
.modal-content .modal-list .modal-item span {
  font-size: 12px;
  color: #ede5ce;
  letter-spacing: 1px;
  text-align: start;
}

.modal-content .modal-list .modal-item img {
  width: 25px;
  height: 25px;
}

/* The Close Button */
.close {
  position: absolute;
  right: 20px;
  top: 23px;
  width: 11px;
  height: 11px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .col2 {
    width: 100%;
    flex: 0 0 100%;
    position: relative;
  }

  .col4 {
    width: 100%;
    flex: 0 0 100%;
    margin: 30px 0 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
  .settingsmain .row {
    margin-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1366px) {
  .modal-content .modal-list {
    flex-direction: column;
    padding: 20px;
    justify-content: start;
    align-items: start;
  }
  .modal-content .modal-list .modal-item {
    width: 100%;
    gap: 6px;
  }
  .modal-content {
    width: 40%;
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .modal-content .modal-list {
    flex-direction: column;
    padding: 20px;
    justify-content: start;
    align-items: start;
  }
  .modal-content .modal-list .modal-item {
    width: 100%;
    gap: 6px;
  }
  .modal-content {
    width: 60%;
  }
}

.loader_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #d0a755;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rakec {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #b3b3b3;
  padding: 0 20px 0 0;
  margin-right: 4px;
}

.lerak {
  margin-left: 8px;
  color: #f5f5f5;
}
.lerak p {
  color: #b3b3b3;
  margin: 0 0 2px 0;
  font-size: 12px;
  line-height: 14px;
  text-wrap: nowrap;
}
.lerak span {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #d0a755;
  font-weight: 700;
  line-height: 16px;
}

.wallet-btn {
  padding: 5.5px 6px !important;
}
.mt2 {
  margin-top: 5px !important;
  display: block;
}

.cursor-pointer{
  cursor: pointer;
}

