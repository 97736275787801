.white {
  color: #fff;
}

.frined-reward-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff0a;
  padding: 12px 16px 12px 16px;
  border-radius: 12px;
  border: 1px solid #ffaa00;
}

.fouteen-size {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}

.frined-reward-bar span {
  font-size: 12px;
}

.frined-reward-bar .icon-point {
  gap: 8px;
}

.frined-reward-bar .icon-point .tool-icon {
  max-width: 19px;
}

.frined-count-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.frined-count-bar .fouteen-size {
  display: flex;
  align-items: center;
  gap: 10px;
}

.frined-count-bar .fouteen-size .d-tool-tip {
  padding: 6px 10px;
  margin: 0;
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-item .user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.friends-list-wrapper {
  background: #ffffff0a;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  overflow: hidden;
}

.friend-list {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px 0;
  min-height: 300px;
  align-content: flex-start;
  max-height: 300px;
  overflow-y: auto;
}
.frined-count-bar img {
  cursor: pointer;
}

.friend-list .friend-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49.5%;
  padding: 10px 20px;
  background: #ffffff0a;
  border-radius: 16px;
  box-sizing: border-box;
}

.friend-list .friend-list-item .icon-point span {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff99;
}

.freind-invite-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;
}

.freind-invite-bar .input-field {
  width: 70%;
  margin: 0;
}

.freind-invite-bar button.d-bg-primary-contained.d-btn {
  width: 27%;
  padding: 31px 0;
}

.freind-invite-bar .input-field input.wallet-input {
  padding: 22px 10px;
}

.friend-list.loader-center {
  justify-content: center;
  align-content: center;
}
.image-loading {
  animation: spin 1s linear infinite;
}

.friend-list .no-data {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  color: rgb(255 255 255 / 60%);
}

.pagination-loader {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .friend-list .friend-list-item {
    width: 100%;
  }

  .freind-invite-bar button.d-bg-primary-contained.d-btn {
    width: 70%;
  }
}
