.post-section {
  padding-block: 24px;
}
.cards-container {
  justify-content: start;
  padding-block: 32px;
  gap: 52px;
}

.post-item {
  width: 30.7%;
  box-sizing: border-box;
  text-decoration: none;
  border: 1px solid #d0a755;
  border-radius: 12px;
  background-color: #08121c;
}

.post-item .post-img {
  width: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  line-height: 0;
  height: 300px;
}

.post-item .post-img img {
  width: 100%;
  transition: all 0.5s ease;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.post-content {
  padding: 30px 20px;
  background: #08121c;
  border-radius: 0 0 14px 14px;
  overflow: hidden;
}

.post-content .post-dates {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #b3b3b3;
}
.post-content h3.tabhead {
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.post-content p {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #b3b3b3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.popup-model .post-content {
  background: #2a303761;
}

.single-post {
  padding: 13% 0;
}

.single-post-content .post-img {
  width: 100%;
  height: 360px;
}

.single-post-content p {
  width: 100%;
  font-size: 14px;
}

.single-post-content p a.post-link {
  color: #fff;
}

body .singlehead {
  font-size: 30px;
}
.single-post-content p.flex-p a img {
  width: 100%;
  max-width: 30px;
}

.single-post-content p.flex-p {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.single-post-content p.flex-p a {
  height: auto;
  line-height: normal;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  body .content-wrapper{
    padding-right: 0;
  }
  .cards-container {
    justify-content: "space-between";
    padding-block: "16px";
    row-gap: "32px";
  }
  .popup-model .post-content {
    background: #2a303761;
  }

  .single-post {
    padding: 0% 0;
  }

  body .single-post-content .post-img {
    width: 100%;
    height: 187px;
  }

  body .single-post-content p {
    width: 100%;
    font-size: 17px;
  }

  body .single-post-content .singlehead {
    font-size: 24px;
    text-transform: capitalize;
    margin: 10px 0;
  }

  a.backbtn {
    display: none;
  }

  .container {
    width: 100%;
    padding: 0 5%;
  }
  .post-item {
    width: 100%;
    margin: 0;
  }

  /* .post-item .post-img {
    height: 220px;
  } */

  .post-content {
    padding: 20px 10px;
  }

  .post-content p {
    font-size: 13px;
    line-height: 22px;
  }

  h3.tabhead {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .post-content h3.tabhead {
    font-size: 20px;
  }
  .post-section {
    padding-top: 0;
    padding-bottom: 24px;
  }
  .cards-container {
    padding-block: 0px;
    row-gap: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .post-item {
    width: 47.4%;
  }

  /* .post-item .post-img {
    height: 220px;
  } */

  .post-content {
    padding: 20px 20px;
  }

  .post-content p {
    font-size: 13px;
    line-height: 22px;
  }
  .row.cards-container {
    justify-content: space-between;
    gap: 30px 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .post-item {
    width: 47.4%;
  }
  .row.cards-container {
    justify-content: space-between;
    gap: 20px 0;
  }

  /* .post-item .post-img {
    height: 240px;
  } */
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  /* .post-item .post-img {
    height: 200px;
  } */

  .post-content p {
    font-size: 14px;
  }
}
