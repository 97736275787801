.points-progress-bar {
  position: relative;
  height: 1.5rem;
  width: 100%;
  background: #ffffff1f;
  border-radius: 20px;
  overflow: hidden;
}

.points-progress-bar .progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 1.5rem;
  margin: 0;
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
  border-radius: 4px;
  transition: width 1s ease-in-out;
  overflow: hidden; /* This ensures that the shadow stays within the progress fill */

}

.points-progress-bar .isbar-loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-in-out;
  animation: shine 2s infinite; /* Infinite shine animation */
  
}

/* Keyframes for the white shadow (shine) */
@keyframes shine {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(200%);
  }
}
