.heros-image-slider.selected-hero .d-image-slider-card::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000a1 100%);
  z-index: 0;
  width: 99%;
  left: 0;
  right: 0;
  margin: auto;
  height: 99%;
}
