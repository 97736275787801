.secure-spot {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/login-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.secure-page {
  background: #08121cad;
  border: 1px solid #d0a755;
  padding: 2.2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  box-sizing: border-box;
  margin: 4vw 0 0 0;
}

.secure-spot h5 {
  color: #ede5ce;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-align: center;
}

.secure-spot .body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.secure-spot .body .para {
  font-size: 18px;
  line-height: 24px;
}

.secure-spot .body p.sub-para {
  font-size: 14px;
}

.secure-spot p {
  text-align: center;
}

.secure-spot .login-form .btn {
  font-size: 18px;
  font-weight: 500;
}

.secure-spot .btn-disabled {
  background-color: #020509;
  color: green;
}
.secure-spot .btn-disabled:hover {
  background-color: #02050957;
  cursor: default;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .secure-spot {
    height: 100vh;
  }
  .secure-spot .login-form {
    border: none;
    width: 100vw;
    height: 100vh;
    justify-content: start;
    padding: 20px;
  }
  .secure-spot .login-form h2.h2head {
    font-size: 55px;
  }
  .secure-spot .login-form .btn {
    font-size: 20px;
  }
}
