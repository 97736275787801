.myheroes .tbbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px;
  border: 1px solid #d0a755;
  margin-bottom: 40px;
  background: rgb(8 18 28 / 75%);
}

.myheroes .tbbox h5 {
  width: 100%;
  flex: 0 0 100%;
  color: #ede5ce;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 15px;
  letter-spacing: 2px;
  text-align: center;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .myheroes .tbbox {
    padding: 20px;
  }

  .myheroes .tbbox h5 {
    font-size: 20px;
  }
}
