.play-feed-visible .d-hero-col {
  width: 85%;
}

.play-feed-visible .d-table-col {
  width: 5%;
}

.d-top-bar button.d-bsr-close {
  margin-right: 0;
  margin-bottom: 0;
}

.play-feed-visible .d-home-table.d-card {
  height: calc(100vh - 140px);
}

.play-feed-visible .d-table-col button.d-bsr-close {
  width: 40px;
  height: 40px;
  padding: 5px; 
}

.mini-game-play .d-bg-primary-card.d-home-table.d-card iframe{
  border-radius: 16px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .mini-game-play .d-bg-primary-card.d-home-table.d-card {
    min-height: 300px;
  }

  .mini-game-play .d-bg-primary-card.d-home-table.d-card iframe {
    height: 300px;
  }

  .d-content.flex.home-table-flex.mini-game-play.play-feed-visible {
    flex-direction: column-reverse;
  }

  .play-feed-visible .d-home-table.d-card {
    height: 300px;
  }

  .play-feed-visible .d-hero-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col button.d-bsr-close {
    display: block;
  }

  .mini-game-play .d-top-bar button.d-bsr-close {
    display: block;
  }


  .mini-game-play .d-top-bar {
    flex-direction: row;
  }

  .mini-game-play .d-top-bar button.d-bsr-close img {
    transform: rotateY(185deg);
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .d-content.flex.home-table-flex.mini-game-play.play-feed-visible {
    flex-direction: column-reverse;
  }

  .play-feed-visible .d-home-table.d-card {
    height: 300px;
  }
  .play-feed-visible .d-hero-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col button.d-bsr-close {
    display: block;
  }

  .mini-game-play .d-top-bar button.d-bsr-close {
    display: block;
  }


  .mini-game-play .d-top-bar {
    flex-direction: row;
  }

  .mini-game-play .d-top-bar button.d-bsr-close img {
    transform: rotateY(185deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mini-game-play .d-bg-primary-card.d-home-table.d-card {
    min-height: 600px;
  }

  .mini-game-play .d-bg-primary-card.d-home-table.d-card iframe {
    height: 600px;
  }

  .d-content.flex.home-table-flex.mini-game-play.play-feed-visible {
    flex-direction: column-reverse;
  }

  .play-feed-visible .d-home-table.d-card {
    height: 300px;
  }

  .play-feed-visible .d-hero-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col button.d-bsr-close {
    display: block;
  }

  .mini-game-play .d-top-bar button.d-bsr-close {
    display: block;
  }


  .mini-game-play .d-top-bar {
    flex-direction: row;
  }

  .mini-game-play .d-top-bar button.d-bsr-close img {
    transform: rotateY(185deg);
  }

}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .d-content.flex.home-table-flex.mini-game-play.play-feed-visible {
    flex-direction: column-reverse;
  }

  .play-feed-visible .d-hero-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col {
    width: 100%;
  }

  .play-feed-visible .d-table-col button.d-bsr-close {
    display: block;
  }

  .mini-game-play .d-top-bar button.d-bsr-close {
    display: block;
  }


  .mini-game-play .d-top-bar {
    flex-direction: row;
  }

  .mini-game-play .d-top-bar button.d-bsr-close img {
    transform: rotateY(185deg);
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {}