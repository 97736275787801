.marketplace-page {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/market-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.marketplace-page span {
  text-align: center;
  width: 100%;
  display: block;
  color: #d0a755;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto";
}
