.bg-heros {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg2.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-news {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg4.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.inner-header {
  padding: 7% 0 0 0;
}

.inner-header p.center {
  width: 65%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  color: #b3b3b3;
  margin: 10px auto;
}

.heroes-tabs ul {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 75%;
}

.heroes-tabs {
  display: flex;
  justify-content: space-between;
  padding: 20px 10.2%;
  background: #08121c95;
}

.heroes-tabs ul a {
  border: 1px solid #b3b3b3;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #b3b3b3;
  padding: 5px 20px;
  text-decoration: none;
  transition: all 0.5s ease;
  text-transform: uppercase;
}

.heroes-tabs ul li {
  margin: 0 10px;
}

.search-bar {
  position: relative;
}

.search-bar img {
  position: absolute;
  bottom: 23%;
  font-size: 24px;
  color: #d0a755;
}
.search-bar input {
  background: transparent;
  box-shadow: none;
  border: 0px;
  padding: 5px 20px 5px 24px;
  border-bottom: 1px solid #d0a755;
  outline: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #ede5ce;
  opacity: 0.4;
}

.heroes-tabs ul a:hover {
  border-color: #343a40;
  color: #343a40;
}

.heroes-tabs ul li.active a {
  color: #d0a755;
  border-color: #d0a755;
}

.product-card {
  width: 22.4%;
  position: relative;
  margin: 20px 20px;
  text-decoration: none;
  box-sizing: border-box;
}

.product-card .card-img {
  width: 90%;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  height: 77%;
  position: relative;
  margin: 8px auto 0 auto;
  border-radius: 40px 40px 0px 0px;
}
.product-card:hover:before {
  /* filter: brightness(0) saturate(100%) invert(98%) sepia(21%) saturate(4942%)
    hue-rotate(301deg) brightness(123%) contrast(101%); */

  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/hover-frame.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.product-card .card-img img {
  width: 102%;
  object-fit: cover;
  transition: all 0.5s ease;
  height: 100%;
  transform-origin: top center;
}

/* .product-card:hover .card-img img {
  transform: scale(1.3);
} */
.product-card .card-img:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000003d;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  border-radius: 32px 32px 0px 0px;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 10;
}

.product-card .card-img button.btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;
  z-index: 999;
  opacity: 0;
  transition: all 0.5s ease;
}

.product-card:hover .card-img img {
  transform: scale(1.1);
}

.product-card:hover .card-img:before {
  opacity: 1;
}

.product-card:hover .card-img button.btn {
  opacity: 1;
}

.product-card h3 {
  padding: 0px 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #0f0d0d;
  position: absolute;
  z-index: 99;
  height: 75px;
  text-decoration: none;
  bottom: 8px;
  display: flex;
  align-items: center;
}

.product-card > div {
  height: 100%;
}
.product-card:before {
  content: "";
  position: absolute;
  width: 100%;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/cardFrame.svg);
  height: 100%;
  left: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 8;
  right: 0;
  margin: auto;
}

.Heroes-List {
  margin-top: -20vw;
}

/* .heroes-tabs-content {
  padding: 70px 0 0 0;
} */

.comingSoon:after {
  content: "Coming Soon";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  background: #0000008c;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
  border-radius: 20px;
  color: #d0a755;
}
.comingSoon {
  pointer-events: none;
}
.heroes-tabs-content .row {
  justify-content: start;
}
.heroes-tabs-content h1 {
  height: 32vh;
  margin: 0 auto;
  display: table;
}
/* .heroes-tabs-content .row .product-card {
  margin: 30px 30px;
} */
.filter-btn span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #d0a755;
  border: 1px solid #d0a755;
  padding: 5px 19px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s ease;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.filter-btn span:hover {
  background: #d0a755;
  color: #0f0d0d;
}
.popup-model {
  position: fixed;
  width: 100%;
  height: 80vh;
  background: #08121c;
  z-index: 9999999;
  left: 0;
  bottom: -120%;
  padding: 4% 0% 24% 0%;
  box-sizing: border-box;
  transition: all 0.5s ease;
}

.popup-model .search-bar {
  padding: 50px 60px 0 20px;
  box-sizing: border-box;
}
.filter-btn {
  text-align: center;
  padding: 3% 0;
  display: none;
}
.popup-model .search-bar input {
  width: 100%;
}

.popup-model .row {
  height: 370px;
  overflow: scroll;
  padding: 0 0 10% 0;
}

.popup-model .heroes-tabs {
  display: block;
  padding: 20px 10px 0px 10px;
}

.popup-model .heroes-tabs ul {
  flex-wrap: wrap;
  width: 100%;
}

.popup-model .heroes-tabs ul li {
  margin: 6px 6px;
}
.popup-model img.close-btn {
  position: absolute;
  right: 7%;
  top: 3%;
  width: 100%;
  max-width: 6%;
  cursor: pointer;
  z-index: 999;
}

.popup-model .search-bar img {
  bottom: 10px;
}

.Open-model {
  bottom: 0%;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .row {
    flex-direction: row;
  }
  .product-card {
    width: 48%;
    margin: 10px 0px;
    height: 220px;
  }
  .product-card .card-img button.btn {
    font-size: 9px;
    padding: 10px;
  }
  .heroes-tabs-content .row {
    justify-content: space-between;
  }

  .product-card .card-img img {
    border-radius: 17px 17px 10px 10px;
    margin-top: 6px;
    width: 100%;
  }

  .product-card:hover .card-img img {
    transform: scale(1.01);
  }

  .product-card .card-img {
    padding: 0 7px;
    width: 152px;
    margin: 0 auto;
  }

  .product-card h3 {
    height: auto;
    font-size: 13px;
    padding: 0 23px;
    bottom: 16px;
    width: 152px;
    right: 0;
    padding: 0 0 0 10px !important;
    box-sizing: border-box;
    left: 10px;
    margin: auto;
  }
  body .login-form h2.h2head {
    font-size: 22px;
    text-align: center;
  }

  body p.msg-text {
    font-size: 15px;
    text-align: center;
  }

  body .verification-form form {
    width: 100%;
  }

  body .code-inputs {
    gap: 10px;
  }

  body .verification-page .check-box {
    align-items: self-start;
  }
  body .login-form form .code-inputs input {
    width: calc(100% / 6);
    height: 40px;
    padding: 10px;
    text-align: center;
  }

  body .contact-us .contact-detail-info .icon-box {
    gap: 10px;
  }

  body .contact-us .contact-detail-info .icon-box a,
  body .contact-us .contact-detail-info .icon-box span {
    font-size: 14px;
  }

  body .contact-us .contact-detail-info .icon-box img {
    width: 23px;
  }

  body .contact-us .contact-info {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .filter-btn {
    display: none;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .heroes-tabs {
    display: none;
  }

  section.inner-header {
    height: auto;
    background-position: 53% 0;
    background-size: 450%;
  }

  .inner-header p.center {
    width: 100%;
  }

  body .Heroes-List .heroes-tabs-content .row {
    flex-direction: row;
  }

  .heroes-tabs-content {
    padding: 30px 0 21% 0;
  }

  .comingSoon:after {
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    background: #08121c8a;
  }
  .footer-menu .menu-box {
    width: 50%;
    margin-bottom: 30px;
    padding: 0;
  }

  .footer-menu .menu-box:first-child,
  .footer-menu .menu-box:last-child {
    width: 100%;
  }

  footer {
    padding: 19% 0;
  }

  .foot-sub {
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  .foot-sub form input[type="text"] {
    font-size: 10px;
    width: 100%;
  }

  .foot-sub p {
    padding: 0 0 30px 0 !important;
  }

  .foot-sub form input[type="submit"] {
    width: fit-content;
  }

  .copyright {
    flex-direction: column;
  }

  .copyright a {
    width: 80%;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .copyright p {
    padding: 0 !important;
  }

  .product-card {
    height: 210px;
    width: 150px;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 10%;
  }

  .product-card .card-img {
    padding: 0 5px;
    width: 150px;
    height: 79%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .product-card {
    width: 25%;
  }

  .product-card .card-img button.btn {
    font-size: 10px;
  }
  .product-card .card-img {
    height: 95%;
  }

  .product-card .card-img {
    border-radius: 20px 20px 40px 30px;
  }
  .heroes-tabs-content .row {
    justify-content: center;
  }

  .product-card h3 {
    height: auto;
    bottom: 15px;
    font-size: 14px;
    padding: 0 23px;
  }
  .login-form form .code-inputs input {
    padding: 10px;
    width: calc(100% / 6);
    height: 60px;
    text-align: center;
  }
  .container {
    width: 100%;
    padding: 0 10%;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .inner-header {
    height: auto;
  }

  .heroes-tabs ul {
    width: 70%;
    overflow: scroll;
    padding: 14px 0;
  }

  .search-bar {
    width: 34%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .search-bar input {
    line-height: 30px;
    height: 30px;
    padding: 5px 20px 5px 34px;
    font-size: 15px;
    width: 60%;
  }

  .search-bar img {
    left: 19%;
    bottom: 18px;
    width: 8%;
  }

  .comingSoon:after {
    font-size: 15px;
    height: 71%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .heroes-tabs ul a {
    font-size: 10px;
  }

  .heroes-tabs {
    padding: 20px 5%;
  }

  .heroes-tabs-content {
    padding: 0 0 11% 0;
  }

  .inner-header p.center {
    width: 80%;
    font-size: 16px;
  }

  .footer-menu .menu-box {
    width: 42%;
    margin-bottom: 40px;
  }

  footer {
    padding: 6% 0;
  }

  .foot-sub form input[type="text"] {
    font-size: 15px;
  }

  .foot-sub .sub {
    width: 50%;
  }

  .foot-sub .sub form {
    width: 100%;
    gap: 14px;
  }

  .foot-sub form input[type="submit"] {
    font-size: 12px;
  }

  .copyright p {
    width: 100%;
  }

  .footer-menu .menu-box:nth-child(3),
  .footer-menu .menu-box:nth-child(2) {
    width: 29%;
  }

  .footer-menu .menu-box:last-child {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .heroes-tabs-content {
    padding: 0 0 11% 0;
  }

  .product-card {
    width: 20.4%;
  }
  .product-card .card-img img {
    border-radius: 27px 27px 50px 50px;
  }
  .product-card .card-img button.btn {
    font-size: 12px;
  }
  .product-card .card-img {
    height: 95%;
  }

  .product-card h3 {
    height: auto;
    bottom: 19px;
    font-size: 17px;
    width: 150px;
    padding: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .container {
    width: 100%;
    padding: 0 5%;
  }
  .inner-header {
    height: auto;
  }
  .heroes-tabs-content .row {
    justify-content: center;
  }

  .heroes-tabs ul {
    width: 77%;
    overflow: scroll;
    padding: 14px 0;
  }

  .search-bar {
    width: 34%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .search-bar input {
    line-height: 30px;
    height: 40px;
    padding: 5px 20px 5px 34px;
    font-size: 17px;
  }

  .search-bar img {
    left: 13%;
    bottom: 20px;
  }

  .comingSoon:after {
    font-size: 18px;
    height: 80%;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .heroes-tabs ul a {
    font-size: 12px;
  }

  .heroes-tabs {
    padding: 20px 5%;
  }

  .inner-header p.center {
    width: 80%;
    font-size: 16px;
  }

  ul.nav a {
    font-size: 13px;
  }
  .heroes-tabs-content {
    padding: 2% 0 10% 0;
  }
  .footer-menu .menu-box {
    padding: 0 20px 0 0;
  }

  .footer-menu .menu-box:first-child {
    width: 33%;
    padding: 0 50px 0 0;
  }

  .footer-menu .menu-box:nth-child(2) {
    width: 20%;
  }

  .footer-menu .menu-box:nth-child(3) {
    width: 20%;
  }

  .product-card .card-img {
    border-radius: 24px 24px 0px 0px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-card {
    width: 21.4%;
  }

  .product-card .card-img img {
    border-radius: 10px 10px 8px 8px;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }

  .inner-header {
    padding: 10% 0 0 0;
    /* height: 600px; */
  }
  .Heroes-List {
    margin-top: -10vw;
  }

  .heroes-tabs {
    padding: 20px 5%;
  }

  .heroes-tabs ul {
    width: 72%;
    overflow: scroll;
    padding: 14px 0;
  }

  .search-bar {
    width: 28%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .search-bar input {
    line-height: 30px;
    height: 40px;
    padding: 5px 20px 5px 34px;
  }
  .heroes-tabs-content {
    padding: 4% 0;
  }

  .search-bar img {
    left: 22%;
    bottom: 22px;
  }

  .comingSoon:after {
    font-size: 18px;
  }

  .heroes-tabs ul a {
    font-size: 16px;
  }
}
