.Hero-single {
  /* background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/singlebanner.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  box-sizing: border-box;
  padding: 6% 0 10px 0;
  position: relative;
  overflow: hidden;
  background-blend-mode: overlay;
}

.Hero-single > * {
  position: relative; /* Ensure content is above the overlay */
  z-index: 2;
}

.links-button {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 193px;
  margin-bottom: 52px;
}

a.backbtn,div.backbtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: #d0a755;
  border: 1px solid #d0a755;
  text-decoration: none;
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  cursor: pointer;
}

.backbtn img {
  width: 100%;
  max-width: 20px;
}

.Hero-single .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}
.charactor-img {
  flex: 1;
  align-self: flex-end;
}

.banner-content {
  flex: 1;
}

.charactor-img img {
  object-fit: cover;
  width: 735px;
  height: 510px;
}

section.single-tabs {
  background: #020508;
}

.Hero-single:before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(2, 5, 8, 0) 0%, #020508 100%);
  width: 100%;
  height: 100px;
  bottom: 0;
  z-index: 5;
}

.single-detail h5 {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #ede5ce;
  margin: 0 0 20px 0;
}
.single-detail p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #b3b3b3;
  margin: 0 0 20px 0;
}

.single-detail h2 {
  line-height: 107%;
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #d0a755;
}

.icon-list {
  display: flex;
  padding: 10px 0;
}

.icon-list img {
  width: 100%;
  max-width: 30px;
  margin: 0 10px 0 0;
}

.single-detail span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #b3b3b3;
}

.icon-list h3 {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #d0a755;
}

.single-detail ul {
  display: flex;
  padding: 10px 0;
}

.single-detail ul li img {
  opacity: 0.4;
}

.single-detail ul li.active img {
  opacity: 1;
}

.pt-4 {
  padding-top: 16px;
}

.single-detail h3 {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #d0a755;
  position: relative;
}
h2.h4head {
  padding-top: 32px;
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 150%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #d0a755;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
}

.single-tab-list {
  padding: 0 0 70px 0;
  display: flex;
  justify-content: space-between;
}

.img-sig img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
}

.img-sig {
  padding: 66px 18px;
}
.single-tab-list .hero-tabs-content span.subline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #ede5ce;
}
.single-tab-list .hero-tabs-content {
  padding: 32% 0 0 0;
}
.single-tab-list .tab-list {
  width: 50%;
  padding-top: 24px;
}

.single-tab-list .tab-list ul {
  width: 90%;
  position: relative;
  z-index: 99;
}

.single-tab-list .hero-tab-content {
  padding-top: 0;
}

.single-tab-list .play-video {
  height: 530px;
}

.single-tab-list video {
  border-radius: 76px;
}

.show-on-web{
  display: flex;
}

.show-on-mobile{
  display: none;
}

@media screen and (max-width: 1200px) {
  .charactor-img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .Hero-single .container {
    flex-direction: column;
  }

  .single-detail h2 {
    font-size: 40px;
    padding-top: 30px;
  }

  .single-tab-list .tab-list ul {
    width: 70%;
  }
  .single-tab-list .play-video {
    width: 300px;
    height: 230px;
  }
  .single-tab-list video {
    border-radius: 38px;
  }
  .single-tabs .single-tab-list {
    flex-direction: column;
  }
  .single-tab-list .tab-list {
    width: 100%;
  }
  .single-tab-list .hero-tab-content {
    margin: 0 0 0 0;
  }

  .single-tab-list .hero-tabs-content {
    padding: 30px 0 0 0;
  }

  .single-tab-list .PlayReverse .row {
    flex-direction: column;
  }

  .img-sig {
    padding: 10px 18px;
  }

  .img-sig img {
    border-radius: 16px;
  }

  .heroes-single {
    background: #020508;
  }

  .single-tabs {
    padding: 17% 0;
  }

  .single-tabs .single-tab-list {
    padding: 16px 0 0 0;
  }

  .single-tabs .single-tab-list .tab-list {
    padding: 0;
  }

  body .single-tabs .single-tab-list .tab-content {
    padding: 0;
  }

  .show-on-web{
    display: none;
  }
  .show-on-mobile{
    display: flex;
    margin: auto;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .single-tab-list .play-video {
    height: 305px;
  }

  .single-tab-list video {
    border-radius: 38px;
  }
  .Hero-single {
    padding: 14% 0 0 0;
  }
  .single-tab-list .tab-list {
    width: 100%;
  }
  .single-detail h2 {
    font-size: 40px;
    margin: 0 0 30px 0;
  }

  .single-tab-list {
    padding: 30px 0 0 0;
  }

  section.single-tabs {
    padding: 4% 0 10% 0;
  }

  .img-sig {
    padding: 39px 8px;
  }

  .single-tabs .single-tab-list .tab-list ul {
    padding: 0 8px 0 16px;
  }
  .single-tab-list .tab-content {
    padding: 20px 30px 0 10px;
    box-sizing: border-box;
  }
  body .single-post-content .post-img {
    width: 90%;
    height: 320px;
  }
  .single-post {
    padding: 11% 0 0 0;
  }
  body .single-post-content .singlehead {
    font-size: 27px;
  }

  .single-tab-list .tab-list ul {
    width: 90%;
  }

  .links-button{
    margin-bottom: 24px;
  }
  .img-sig img {
    border-radius: 20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .Hero-single {
    padding: 11% 0 0 0;
  }
  .single-tab-list .play-video {
    height: 375px;
  }

  .single-tab-list video {
    border-radius: 46px;
  }
  .single-detail h2 {
    font-size: 58px;
  }

  .links-button{
    margin-bottom: 24px;
  }

  .single-tab-list {
    padding: 30px 0 0 0;
  }

  section.single-tabs {
    padding: 8% 0 10% 0;
    /* height: 40vh; */
  }

  .img-sig {
    padding: 38px 8px;
  }

  .single-tabs .single-tab-list .tab-list ul {
    padding: 0 8px 0 16px;
  }
  .single-tab-list .tab-content {
    padding: 20px 30px 0 10px;
    box-sizing: border-box;
  }

  .single-detail span {
    margin: 30px 0 0px 0;
    display: block;
  }
  .single-detail ul {
    position: relative;
    z-index: 00;
  }
  body .single-post-content .post-img {
    width: 90%;
    height: 420px;
  }

  body .single-post-content .singlehead {
    font-size: 40px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-tab-list .play-video {
    height: 441px;
  }

  .single-tab-list video {
    border-radius: 53px;
  }
  .single-tab-list .tab-content {
    padding: 50px 0 0 30px;
    height: auto;
  }

  .single-tab-list .tab-list ul {
    padding: 32px 25px 32px 18px;
  }

  .single-tab-list .tab-list ul li {
    width: 21%;
  }
  .single-tab-list .hero-tab-content {
    padding: 0 3%;
  }
  .img-sig {
    padding: 17px 15px;
  }
  .single-tab-list .hero-tab-content {
    padding: 0 3%;
  }

  .img-sig img {
    border-radius: 30px;
  }
  section.single-tabs {
    padding: 0 0 80px 0;
  }

  .Hero-single {
    background-position: 50% -10px;
  }
}
