section.contact-us {
  padding: 10% 0;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/explore-bg.png);
  background-size: cover;
  background-position: center;
}

.contact-us h2.h2head.mb5 {
  text-align: center;
}

.contact-us .contact-info {
  padding: 20px 30px;
  border: 1px solid #d0a755;
  background: #08121ca3;
  width: 80%;
}

.contact-us .contact-info p {
  color: #b3b3b3;
  font-size: 17px;
  line-height: 29px;
  margin-top: 9px;
  opacity: 0.8;
}

.contact-us .contact-detail-info:nth-child(3) {
  margin-top: 50px;
}

.contact-us .contact-detail-info span {
  color: #d0a755;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1px;
}

.contact-us .contact-detail-info .icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  opacity: 0.8;
}

.contact-us .contact-detail-info .icon-box a,
.contact-us .contact-detail-info .icon-box span {
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #b3b3b3;
  text-decoration: none;
}
.swal2-actions button {
  background: #d0a755 !important;
  color: #000 !important;
}
.contact-detail-info {
  margin-top: 40px;
}

.form form .contact-input {
  width: 46%;
}

.form form {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  justify-content: space-between;
}

.form form label {
  width: 100%;
  display: block;
}

.form form label input,
.form form label textarea,
.form form label select {
  width: 100%;
  padding: 14px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
  border: 1px solid #b3b3b3;
  background: #181c1f;
  outline: none;
  color: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.form form input[type="submit"] {
  color: #0f0d0d;
  background: #d0a755;
  border-radius: 14px;
  padding: 17px 30px;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 1px;
}

.form form label textarea {
  height: 220px;
  resize: none;
}
.form form label select {
  color: rgb(237 229 206 / 40%);
}

.form form label select option {
  color: #b3b3b3;
}
.form form .recaptcha {
  width: 100%;
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .form form {
    flex-direction: column;
  }
  .form form .contact-input {
    width: 100%;
  }

  .contact-us .col-5 {
    width: 35%;
  }
  .contact-us .contact-detail-info .icon-box {
    gap: 5px;
  }
  .contact-us .contact-detail-info .icon-box a,
  .contact-us .contact-detail-info .icon-box span {
    font-size: 13px;
    line-height: 150%;
  }
  .contact-us .contact-detail-info .icon-box img {
    width: 15px;
    height: 15px;
  }
  .contact-us .contact-info {
    padding: 20px 30px;
    border: 1px solid #d0a755;
    background: #08121ca3;
    width: 80%;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .form form {
    flex-direction: column !important;
  }
  .form form .contact-input {
    width: 100% !important;
  }
}
