.d-menu-link {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  text-decoration: none;
  transition: all 0.5s ease;
  flex-direction: row;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}

.d-menu-link div {
  margin-left: 12px;
  color: #ffffff87;
}

.d-menu-link:hover,
.d-menu-link.active {
  background: rgb(255 255 255 / 4%);
}

.d-menu-link.active div,
.d-menu-link:hover div {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  white-space: nowrap;
}


.d-menu-link div span {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  border: 1px solid #ff8900;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 10px;
  color: #fff;
  margin-top: 0 !important;
}

.d-menu-link:hover img {
  filter: brightness(0) saturate(100%) invert(64%) sepia(44%) saturate(2756%)
    hue-rotate(1deg) brightness(108%) contrast(103%);
}