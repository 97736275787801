.topbanner {
  padding: 16% 0;
  /* background: url(https://d2j04pqii01bv9.cloudfront.net/Images/new-bannar.png); */
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #ede5ce;
}
.emerge .row {
  align-items: center;
}
.topbanner a.btn {
  font-weight: bold;
}
section.topbanner video,
.heroInfo video {
  width: 100%;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100%;
  border-radius: 0;
  z-index: 1;
  object-fit: cover;
}
.heroInfo .container > img {
  width: 65%;
}
.emerge .container .emerg-content {
  padding: 0;
}
section.topbanner {
  position: relative;
}
.topbanner .container {
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  z-index: 2;
}
.playwin .row {
  align-items: center;
}
.heroInfo .container {
  position: relative;
  z-index: 2;
}
.ouralliances {
  background: linear-gradient(
    45deg,
    rgba(208, 167, 85, 0.4),
    rgba(254, 223, 166, 0.4)
  );
  padding: 5% 0;
}

.imglist {
  margin-top: 81px;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.imglist .slick-track {
  transition-timing-function: linear !important;
}
.imglist img {
  padding: 0 8px;
  box-sizing: border-box;
  height: 80px;
  width: 30%;
  object-fit: contain;
}
.imglist a {
  margin-bottom: 40px;
}
.imglist img:nth-child(4),
.imglist img:nth-child(5),
.imglist img:nth-child(6){
  padding : 20px
}

.text-center {
  text-align: center;
}

.topbanner ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heroInfo a.btn {
  margin: 25px auto 0;
  display: table;
}
.topbanner ul li {
  margin: 0 14px;
  transition: all 0.5s ease;
  transform: scale(1);
}

.topbanner ul li:hover {
  transform: scale(1.05);
}

/* .subHead {
  font-size: 1.5rem;
  font-family: "Rakkas";
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 120%;
  margin-bottom: 2rem;
} */

.topbanner h1 {
  font-size: 24px;
  font-family: "Roboto";
  margin: 30px 0 20px;
  font-weight: 900;
  letter-spacing: 0.05em;
}

.topbanner p {
  margin-bottom: 20px;
  font-size: 20px;
  letter-spacing: 0.05em;
}
.latesttop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.latestnews {
  padding: 3.5% 0 2% 0;
  position: relative;
}

.newsmain .slick-prev,
.road-item-list .slick-prev {
  left: initial;
  right: 150px;
}

.newsmain .slick-arrow,
.road-item-list .slick-arrow {
  top: -51px;
  border: 1px solid #d0a755;
  padding: 20px;
  box-sizing: border-box;
  z-index: 100;
}
.newsmain .slick-slide {
  padding: 0 25px;
  box-sizing: border-box;
}
.road-item-list .slick-slide {
  box-sizing: border-box;
}
.newsmain .slick-arrow:hover,
.road-item-list .slick-arrow:hover {
  border: 1px solid #ffe39e;
}

body .newsmain .slick-disabled,
body .road-item-list .slick-disabled {
  border-color: #39372a;
}

.newsmain .slick-arrow:hover::before,
.road-item-list .slick-arrow:hover::before {
  filter: brightness(0) saturate(100%) invert(98%) sepia(21%) saturate(4942%)
    hue-rotate(301deg) brightness(123%) contrast(101%);
}

.newsmain .slick-disabled:hover::before,
.road-item-list .slick-disabled:hover::before {
  opacity: 1;
}

.newsmain .slick-next,
.road-item-list .slick-next {
  right: 90px;
}

.newsmain .slick-next,
.road-item-list .slick-next {
  right: 90px;
}
.newsde {
  background: #020509;
  border-radius: 0px 0px 14px 14px;
  padding: 20px;
  /* box-sizing: border-box; */
  height: 170px;
}

.newsde p {
  margin: 0 0 10px;
  color: #b3b3b3;
}

.newsbox a {
  color: #ede5ce;
  font-size: 24px;
  text-decoration: none;
  font-family: "Rakkas";
  letter-spacing: 0.1em;
}

.newsbox img {
  border-radius: 14px 14px 0px 0px;
  transition: all 0.3s ease;
  transform: scale(1);
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

.newsbox img:hover {
  transform: scale(1.1);
}

.newsbox {
  position: relative;
  overflow: hidden;
  border-radius: 14px 14px 0px 0px;
}
.h3head {
  letter-spacing: 0.05em;
  font-size: 24px;
  font-weight: 900;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Roboto";
}

.newsmain:after {
  content: "";
  background: linear-gradient(270deg, #08121c 0%, rgba(8, 18, 28, 0) 100%);
  width: 177px;
  height: 395px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.newsmain {
  position: relative;
}

.bbtn {
  border: 1px solid #d0a755;
  padding: 8.5px 20px;
  color: #d0a755;
  letter-spacing: 0.05em;
  font-size: 20px;
  background: transparent;
  text-decoration: none;
}

.newsmain .slick-next:before,
.road-item-list .slick-next:before {
  content: "";
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/arrowr.svg);
  background-size: contain;
  width: 30px;
  height: 30px;
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 22px;
}

.newsmain .slick-prev:before,
.road-item-list .slick-prev:before {
  content: "";
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/arrowl.svg);
  background-size: contain;
  width: 30px;
  height: 30px;
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  width: 22px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .homepage .row { */
/* flex-wrap: nowrap; */
/* } */

/* .Sidebar-menu .leftdiv ul.nav li:last-child {
  display: none;
} */

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.emerg-content {
  padding: 0 0 0 20%;
}

.emerg-image img.emerg-main-img {
  width: 85%;
}

.emerg-image {
  position: relative;
  width: 100%;
  display: block;
  text-align: end;
}

.emerg-image img.emerg-foot-img {
  position: absolute;
  width: 100%;
  max-width: 74%;
  bottom: -13%;
  left: -2%;
}

.emerge {
  padding: 8.5% 0;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/sec2bg.png) #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Trailer section  */
.trailer {
  padding: 8.5% 0;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg31.png) #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.trailer-container .row {
  margin-bottom: 20px;
}

h2.h2head {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: linear-gradient(94.19deg, #d0a755 2.13%, #fedfa6 92.73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.tab-list ul {
  display: flex;
  justify-content: space-around;
  width: 80%;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/tabsline.svg);
  background-repeat: no-repeat;
  background-position: 41%;
  background-size: contain;
  align-items: center;
  height: 100px;
  animation: fadeIn 2s;
  /* margin: 30px 0; */
}
.tab-list ul li {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0 0 0;
}

.tab-list ul li a img {
  width: 100%;
  opacity: 0.4;
  transition: all 0.5s ease;
}

.tab-list ul li a:hover img {
  opacity: 1;
}

.tab-list ul li a {
  background: #000;
  display: inline-block;
  width: 75%;
  border-radius: 50px;
  height: 93%;
  transition: all 0.5s ease;
}

.tab-list ul li.active a {
  width: 100%;
}

.tab-list ul li.active a img {
  opacity: 1;
}
.tab-content {
  /* padding: 40px 0px 40px 0px; */
  width: 100%;
  /* height: 150px; */
}
.road-item-list .slick-prev {
  right: 240px;
}

.road-item-list .slick-next {
  right: 190px;
}

h3.tabhead {
  font-family: "Rakkas";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #ede5ce;
  margin: 0;
  transition: all 0.5s ease;
  /* animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s; */
}
.tab-content.active h3.tabhead {
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-tabs-content {
  height: 650px;
}

.tab-content p,
.hero-tabs-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #b3b3b3;
  animation: fadeIn 2s;
  padding: 0 16% 0 0;
  /* height: 130px; */
}

.hero-tabs-list ul li a:hover img {
  opacity: 1;
}

.col-7 {
  width: 60%;
}

.col-5 {
  width: 40%;
}

.play-video {
  width: 700px;
  height: 500px;
  padding: 32px 17px;
  box-sizing: border-box;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.roadmap-box .roadmap-item li {
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
}

.roadmap-box .roadmap-item li::before {
  content: "";
  margin-left: -17px;
  margin-right: 12px;
  width: 5px;
  height: 5px;
  display: inline-block;
  background: #66ff00;
  border-radius: 80px;
}

.roadmap-box .roadmap-item:last-child li::before {
  background: #b3b3b3;
}

video {
  position: relative;
  z-index: 8;
  width: 100%;
  margin: 0 auto;
  display: table;
  height: 100%;
  object-fit: cover;
  border-radius: 46px;
}
.playwin {
  padding: 10% 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.playwin .col-7,
.playwin .col-5 {
  width: 50%;
}
.playwin .play-content .btn {
  margin: 10px 0;
}
.play-video:before {
  box-sizing: border-box;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/Frame.svg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
}
.play-content p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #b3b3b3;
  padding: 0 23.7% 0 0;
  margin: 25px 0;
}
.mb5 {
  margin-bottom: 30px;
}

.ChooseHeor {
  padding: 17% 0;
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/choose-bg.png)
    #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.center {
  text-align: center;
}

.ChooseHeor .center {
  width: 50%;
  margin: 0 auto;
}
.hero-tabs-list ul {
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/tabsline.svg);
  display: flex;
  justify-content: space-between;
  width: 55%;
  margin: 0 auto;
  padding: 12px 40px 0 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
}
.hero-tabs-list ul li {
  width: 20%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 25px;
  transition: all 0.5s ease;
}

.hero-tabs-list ul li img {
  width: 100%;
  opacity: 0.4;
  transition: all 0.5s ease;
}

.hero-tabs-list ul li.active {
  padding: 0;
  width: 20%;
}

.hero-tabs-list ul li.active img {
  opacity: 1;
}
.hero-tab-img img {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  display: table;
  border-radius: 0;
  transition: all 0.5s ease;
  border-radius: 20px;
  width: 600px;
  height: 540px;
  object-fit: cover;
}
.hero-tab-content .hero-tab-img {
  padding: 0 50px;
}
.hero-tab-img:hover img {
  transform: translateY(-15px);
}

.hero-tab-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-tab-content {
  padding: 70px 7% 0 2%;
}

.roadmap {
  padding: 8% 0;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/bg21.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.map-item p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #b3b3b3;
  height: 100px;
  text-align: center;
}

.roadmap p.center {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
  color: #b3b3b3;
  width: 40%;
  margin: 50px auto;
}

.road-item-list {
  width: 100%;
  margin-left: auto;
  padding: 6% 0 0 0;
  overflow: hidden;
  position: relative;
}

.map-item {
  width: 33%;
  padding: 0 150px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
}

.map-item h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #b3b3b3;
}

.roadmap-img {
  width: 100%;
  text-align: center;
  padding: 11px 0;
}
body .road-item-list .slick-list {
  overflow: visible;
}
.roadmap-img img {
  width: 100%;
  max-width: 40px;

  margin: 0 auto;
}
.road-item-list .active h4,
.road-item-list .active h3,
.road-item-list .active p {
  color: #d0a755;
}
.road-item-list .active .roadmap-img img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(30%) saturate(7070%)
    hue-rotate(312deg) brightness(86%) contrast(89%);
}
body .road-item-list .slick-list .slick-track {
  margin-left: 20%;
  margin-right: 10%;
}
body .road-item-list .slick-list {
  overflow: visible;
  margin-right: 20%;
}

.road-item-list .slick-arrow {
  top: -80px;
}

.roadmap-box {
  border: 1px solid #d0a755;
  background-color: #08121cb3;
  backdrop-filter: blur(5px);
}

.roadmap-box .roadmap-item {
  border-bottom: 1px solid #d0a755;
  display: flex;
  padding: 40px;
}

.roadmap-question {
  flex: 1;
}
.roadmap-question h3 {
  font-size: 50px;
  font-family: "Rakkas";
  color: #d0a755;
  letter-spacing: 5px;
}
.roadmap-question p {
  font-size: 24px;
  font-family: "Rakkas";
  margin: 0;
  letter-spacing: 2px;
}
.roadmap-list {
  flex: 1;
}
.roadmap-list ul {
  list-style-type: disc;
  font-size: 17px;
}

.fight-ground video {
  width: 100%;
  border-radius: 0;
  height: 100%;
  object-fit: cover;
}
.fight-ground {
  width: 100%;
  height: 600px;
}
.PlayReverse .row {
  flex-direction: row-reverse;
}

.fight-ground video {
  width: 100%;
  border-radius: 0;
  height: 100%;
  object-fit: cover;
}
.fight-ground img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.play-image {
  width: 100%;
}

.play-image img {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  display: table;
}
.map-item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #b3b3b3;
  top: 24%;
  left: 20px;
  right: 0;
  margin: 0 auto;
}

.map-item.active::before {
  background: #d1a354;
}

body
  .road-item-list
  .slick-list
  .slick-track
  .slick-slide:first-child
  .map-item.active:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border: 2px solid #d1a354;
  left: 0;
  top: 21%;
  transform: rotate(45deg);
}

body
  .road-item-list
  .slick-list
  .slick-track
  .slick-slide:last-child
  .map-item::before {
  /* width: 32%; */
  left: 0;
  right: inherit;
}

.road-item-list:before {
  content: "";
  background: linear-gradient(270deg, #04070ea3 20%, rgba(8, 18, 28, 0) 80%);
  width: 177px;
  height: 395px;
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}

.playIcon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 38%;
  bottom: 0;
  margin: 0 auto;
  z-index: 99999;
  width: 10%;
  cursor: pointer;
  height: 20%;
}

.playIcon img:hover {
  filter: brightness(0) saturate(100%) invert(31%) sepia(18%) saturate(408%)
    hue-rotate(169deg) brightness(93%) contrast(93%);
  opacity: 0.9;
}

.Sidebar-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #020509;
  top: 0;
  left: -100%;
  z-index: 999;
  transition: all 0.5s ease;
  overflow-y: scroll;
}

.Sidebar-menu .row {
  justify-content: center;
  width: 100%;
  padding: 0 0 30px 0;
}

.Sidebar-menu .leftdiv ul.nav {
  width: 100%;
  margin: 20px 0;
  flex-direction: column;
}

.Sidebar-menu .rightdiv {
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.Sidebar-menu .leftdiv ul.nav li {
  margin: 20px 0;
}

.Sidebar-menu .leftdiv ul.nav li a {
  color: #ede5ce;
  font-size: 20px;
  letter-spacing: 0.05em;
}

.Sidebar-menu ul.socil {
  margin: 30px 0;
  padding: 30px 0;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid #191c1f;
  border-top: 1px solid #191c1f;
  align-items: center;
}

.Sidebar-menu .rightdiv ul.nav {
  margin: 20px 0;
  padding: 12px 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #ede5ce;
}

.Sidebar-menu .rightdiv button.btn {
  padding: 12px 35px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #0f0d0d;
}

.Sidebar-menu .close-btn {
  width: 100%;
  max-width: 20px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.toggle-menu {
  display: none;
  cursor: pointer;
}
.head .leftdiv img {
  width: 100%;
  max-width: 100%;
}
.head .leftdiv > a {
  width: 240px;
  display: table;
  padding: 16px 0;
  margin-right: 20px;
}
.rewamp-space {
  padding: 4% 0;
}
.rewamp-space .row {
  align-items: center;
}
.heroInfo {
  background: url(https://d2j04pqii01bv9.cloudfront.net/Images/hero-info-new.png);
  background-size: cover;
  background-position: center;
  padding: 8% 0;
  text-align: center;
  position: relative;
  z-index: 9;
}
.heroInfo:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000096;
  left: 0;
  top: 0;
  z-index: 1;
}
.heroInfo video {
  z-index: 0;
}
.heroInfo h3.tabhead {
  padding: 20px 0 0 0;
}

.heroInfo p {
  color: #b3b3b3;
}

.faq summary::marker {
  font-size: 0;
}

.faq summary {
  position: relative;
}

.faq summary:after {
  content: url("https://d2j04pqii01bv9.cloudfront.net/Images/arrow-down.svg");
  color: #d0a755;
  font-family: monospace;
  right: 0;
  position: absolute;
  transition: 0.5s all;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100%;
  top: 0;
}
.faq h2,
.faq h3 {
  text-align: center;
}

.faq-list details {
  border: 1px solid #d0a755;
  margin: 0 0 20px 0;
  padding: 20px 20px;
}

.faq-list {
  margin-top: 40px;
}

.faq-list details summary {
  color: #b3b3b3;
  font-size: 17px;
  font-family: "Roboto";
  font-weight: bold;
  cursor: pointer;
}

.faq-list details p {
  font-size: 16px;
  color: #b3b3b3;
  line-height: 29px;
  margin: 20px 0 0 0;
}
.faq details[open] summary:after {
  transform: rotate(-180deg);
  transition: 0.5s all;
}
.faq {
  padding: 8% 0;
  background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/explore-bg.png);
  background-size: cover;
  background-position: center;
}

.hero-tabs-content p,
.tab-content p {
  padding: 0 10% 0 0;
}

.trailer-container iframe {
  height: 570px;
  width: 65%;
  margin: 0 auto;
  display: table;
}
.topbanner img {
  width: 20%;
}

.custom-row-style {
  align-items: flex-start;
  max-width: 1024px;
  margin: 40px auto;
}
.ways-Item p {
  margin: 0;
}

.matrices-wrapper .video-container {
  margin-top: 17px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .faq-list details {
    padding: 20px 40px 20px 20px;
  }
  .newsde {
    height: auto;
  }
  .trailer-container iframe {
    height: 200px;
    width: 100%;
  }

  .faq summary:after {
    right: -30px;
  }
  .chat-model {
    width: 79%;
    right: 10px;
    padding: 30px 20px;
  }

  .chat-model h2.h2head {
    font-size: 20px;
  }

  .chat-model div span {
    font-size: 16px;
  }

  .chat-model div img {
    width: 30px;
  }

  .chat-model div {
    gap: 10px;
  }

  .chat-model ul button,
  .chat-model ul a {
    font-size: 15px !important;
  }

  body p.error-text {
    font-size: 16px;
  }

  body .login-form form label span {
    font-size: 14px;
  }

  body .login-form form label.has-value span {
    font-size: 10px;
    top: 9px;
  }

  body .check-box input#Stay {
    width: 15px;
    height: 15px;
  }

  body .login-form .form-link a {
    font-size: 12px;
  }

  .Sidebar-menu ul.socil li:first-child {
    margin: 0;
  }

  .lefttext {
    width: 100%;
    flex: 0 0 100%;
  }
  .head .leftdiv span img {
    width: 15px;
  }

  .head .leftdiv span {
    justify-content: center;
    text-transform: uppercase;
  }

  ul.nav li .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    padding: 0;
  }

  .Sidebar-menu .rightdiv {
    width: 100%;
  }

  .head .leftdiv {
    width: 100%;
  }
  .imglist img {
    width: 45%;
    margin-bottom: 20px;
  }

  .imglist img:nth-child(4),
  .imglist img:nth-child(5),
  .imglist img:nth-child(6){
    padding : 10px
  }

  .imglist {
    margin-top: 20px;
  }

  .head .leftdiv > a {
    width: 220px;
    padding: 0;
  }
  .head .leftdiv img {
    width: 50px;
  }
  .hero-tab-content .hero-tab-img {
    padding: 0;
  }
  .play-image {
    margin: 30px 0 0 0;
  }
  .Open-menu {
    left: 0;
  }
  .latestnews {
    padding: 70px 5px 70px 5px;
  }

  body .Sidebar-menu .rightdiv,
  body .Sidebar-menu .leftdiv ul.nav {
    display: block;
    text-align: center;
  }

  .Sidebar-menu .rightdiv ul.nav {
    display: inline-block;
  }

  .Sidebar-menu .rightdiv button.btn {
    margin: 0 auto;
  }

  .Sidebar-menu ul.socil img {
    width: 100%;
    max-width: 90%;
  }
  .Open-menu {
    left: 0;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .toggle-menu {
    display: block;
  }
  .rightdiv,
  .head .leftdiv ul.nav {
    display: none;
  }
  .head {
    padding: 20px 20px 13px 20px;
    background: #0a0a0f;
    position: inherit;
  }

  .topbanner {
    padding: 30% 0px 30% 0px;
  }

  .topbanner ul {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .topbanner ul li {
    margin: 10px 0;
  }

  .topbanner img {
    width: 100%;
    max-width: 100%;
  }

  .topbanner h1 {
    margin: 30px 0px 10px;
  }

  .row {
    flex-direction: column;
  }

  .col-6 {
    width: 100%;
  }

  .emerg-content {
    padding: 0 20px 0 20px;
  }

  h2.h2head {
    font-size: 40px;
  }

  section.emerge {
    padding: 10% 0px 34% 0px;
  }

  .tab-content {
    height: auto;
    /* padding: 70px 0 30px 0; */
  }

  .tab-list ul li {
    width: 40%;
  }

  .tab-list ul {
    width: 100%;
    padding: 0 16px 0 16px;
    box-sizing: border-box;
    background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/mobile-line.svg);
  }

  .tab-content p,
  .hero-tabs-content p {
    padding: 0;
    height: auto;
  }

  /* .tab-list { */
  /* padding: 40px 0 0 0; */
  /* } */

  .col-7 {
    width: 100%;
  }
  .heroInfo img {
    width: 80%;
  }

  .playwin .col-7,
  .playwin .col-5 {
    width: 100%;
  }

  video {
    border-radius: 21px;
  }
  .play-video {
    width: 100%;
    height: 240px;
    padding: 14px 10px;
  }
  .homepage .container {
    max-width: 380px;
  }

  .playIcon img {
    width: 11%;
    height: 30%;
    top: 33%;
  }

  .col-5 {
    width: 100%;
  }

  .play-content {
    padding: 50px 10px 0 10px;
  }

  .play-content p {
    padding: 0;
  }

  .playwin {
    background-position: 44% 370%;
  }

  .ChooseHeor {
    background: #000;
  }

  .ChooseHeor .center {
    width: 100%;
  }

  .hero-tabs-list ul {
    width: 100%;
    padding: 8px 11px 0px 20px;
    box-sizing: border-box;
    background-size: contain;
    background-image: url(https://d2j04pqii01bv9.cloudfront.net/Images/mobile-line.svg);
    height: 110px;
  }

  .hero-tabs-list ul li {
    padding: 0 9px;
  }

  .hero-tab-content {
    padding: 10% 0 0 0;
  }

  .hero-tab-img img {
    max-width: 90%;
    border-radius: 40px;
    height: auto;
  }

  .hero-tabs-content {
    padding: 30px 20px 0 20px;
  }

  .PlayReverse .row {
    flex-direction: column-reverse;
  }

  .roadmap p.center {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 30px 0;
  }

  .road-item-list {
    padding: 110px 0 50px 0px;
  }

  body .road-item-list .slick-list .slick-track {
    margin-left: 10%;
  }

  .map-item {
    padding: 0 40px 0 0;
  }

  body .road-item-list .slick-list {
    margin: 0;
  }

  .road-item-list:before {
    width: 0;
  }

  .road-item-list .slick-next {
    right: 145px;
  }

  .road-item-list .slick-prev {
    right: 196px;
  }

  .roadmap-box .roadmap-item {
    flex-direction: column;
    padding: 16px;
  }

  .roadmap-question h3 {
    font-size: 40px;
  }
  .roadmap-question p {
    line-height: 30px;
  }

  .roadmap-list ul {
    padding: 16px;
  }

  .fight-ground {
    height: 230px;
  }

  footer .container {
    flex-direction: column;
  }

  footer .container p {
    padding: 0 10px 20px 10px;
  }

  footer {
    padding: 20px 0;
  }

  ul.socil {
    margin-right: auto;
    margin-left: 0;
  }

  .newsmain:after {
    width: 0;
  }

  a.bbtn {
    padding: 9px 12px;
    font-size: 16px;
  }
  .single-post {
    padding: 11% 0 0 0;
  }

  .newsmain .slick-next {
    right: 53px;
  }

  .newsmain .slick-arrow {
    padding: 19px 20px;
    top: -48px;
  }

  .newsmain .slick-prev {
    right: 103px;
  }
  .newsmain .slick-slide {
    padding: 0 10px;
  }
  .imglist img {
    height: 50px;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .play-video {
    height: 230px;
  }
  body .login-page {
    height: auto;
    padding: 10% 0;
  }
  .login-form {
    width: 70%;
  }

  p.error-text {
    font-size: 16px;
  }

  .login-form form label span {
    font-size: 14px;
  }

  .login-form form label input {
    font-size: 12px;
  }

  .login-form form label.has-value span {
    font-size: 10px;
    top: 9px;
  }

  .login-form form label {
    font-size: 16px;
  }

  .check-box input#Stay {
    width: 15px;
    height: 15px;
  }

  .login-form .form-link a {
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  body .login-form {
    width: 70%;
  }
  .newsde {
    height: 120px;
  }
  .imglist img {
    height: 50px;
  }

.imglist img:nth-child(4),
.imglist img:nth-child(5),
.imglist img:nth-child(6){
  padding : 10px
}
  .trailer-container iframe {
    height: 340px;
  }
  .login-page {
    height: auto;
    padding: 20% 0;
  }

  body p.error-text {
    font-size: 18px;
  }
  .rightdiv,
  .head .leftdiv ul.nav {
    display: none;
  }
  .head .leftdiv {
    width: 100%;
  }
  .head .Sidebar-menu .leftdiv img {
    width: auto;
  }
  .Sidebar-menu .rightdiv ul.nav {
    display: inline-block;
  }

  .Sidebar-menu .rightdiv button.btn {
    margin: 0 auto;
  }

  .login-form .soical-icons a img {
    width: 3.5vw;
  }

  .login-form .soical-icons {
    margin: 14px;
  }
  .login-form .soical-icons a {
    margin: 0 0.3vw;
    display: inline-table;
  }
  .Sidebar-menu ul.socil img {
    width: 100%;
    max-width: 90%;
  }
  .Open-menu {
    left: 0;
  }
  .lefttext {
    width: 100%;
    flex: 0 0 100%;
  }
  .toggle-menu {
    display: block;
  }
  ul.nav a,
  ul.nav li > span {
    font-size: 13px;
  }
  .head .leftdiv > a {
    width: 210px;
  }
  .rightdiv,
  .head .leftdiv ul.nav {
    display: none;
  }
  section.Sidebar-menu.Open-menu {
    left: 0;
  }

  section.Sidebar-menu {
    display: flex;
  }

  .Sidebar-menu .leftdiv ul.nav,
  .Sidebar-menu .rightdiv {
    display: flex;
  }

  .toggle-menu {
    display: block;
    /* width: 25%; */
  }
  .btn {
    /* padding: 9px 20px; */
    font-size: 14px;
  }
  /* .log-btn {
    padding: 9px 20px;
    font-size: 14px;
  } */
  .heroInfo img {
    width: 60%;
  }
  header.head {
    padding: 11px 30px;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .topbanner img {
    width: 100%;
    max-width: 70%;
  }

  .topbanner h1 {
    margin: 0;
  }
  .PlayReverse .row .play-video {
    float: right;
  }

  .topbanner p {
    margin: 10px 0;
  }

  section.topbanner {
    padding: 12% 0 6% 0;
  }

  .topbanner ul {
    width: 70%;
    margin: 0 auto;
  }

  .topbanner ul li img {
    max-width: 100%;
  }

  .newsbox a {
    font-size: 12px;
  }

  h2.h2head {
    font-size: 42px;
  }

  .tab-content {
    height: auto;
    padding: 0;
  }

  h3.tabhead {
    font-size: 16px;
  }

  .tab-content p,
  .hero-tabs-content p {
    padding: 0 5% 0 0;
    font-size: 10px;
    height: auto;
  }

  .tab-list ul li {
    width: 20%;
  }
  .play-video {
    width: 360px;
    height: 290px;
    padding: 33px 9px;
  }
  .playwin .col-5 {
    padding: 0 0 0 40px;
    box-sizing: border-box;
  }

  .PlayReverse .row .col-5 {
    padding: 0 40px 0 0;
  }
  video {
    border-radius: 27px;
  }

  .play-content p {
    padding: 0;
    font-size: 11px;
  }

  .hero-tabs-list ul li {
    padding: 0 14px;
  }

  .hero-tabs-list ul {
    padding: 6px 40px 0 40px;
    width: 70%;
  }

  .hero-tabs-list ul li.active {
    width: 17%;
  }

  .hero-tab-content {
    padding: 0;
  }

  .hero-tab-img img {
    max-width: 88%;
  }

  .play-image img {
    margin: inherit;
    max-width: 83%;
  }

  .roadmap p.center {
    width: 67%;
    font-size: 15px;
    margin: 30px auto;
  }

  .road-item-list {
    padding: 10% 0 0 0;
  }

  .map-item {
    padding: 0 50px 0 0;
  }

  .road-item-list .slick-next {
    right: 90px;
  }
  .fight-ground {
    height: 260px;
  }
  .road-item-list .slick-prev {
    right: 140px;
  }

  .newsmain .slick-slide {
    padding: 0 10px;
  }

  .bbtn {
    padding: 7px 17px;
    font-size: 13px;
  }

  .newsmain .slick-arrow,
  .road-item-list .slick-arrow {
    padding: 15px 15px;
  }

  .newsmain .slick-next:before,
  .road-item-list .slick-next:before {
    width: 17px;
  }

  .newsmain .slick-arrow,
  .road-item-list .slick-arrow {
    top: -45px;
  }

  .newsmain .slick-next {
    right: 71px;
  }

  .newsmain .slick-prev {
    right: 114px;
  }

  .tab-list ul {
    width: 90%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 70px;
  }

  .mb5 {
    margin-bottom: 0;
  }

  .map-item p {
    font-size: 14px;
    height: 80px;
  }
  .roadmap-img img {
    width: 28px;
  }
  .map-item:before {
    top: 26%;
  }
  .map-item h4 {
    font-size: 16px;
  }

  footer {
    padding: 13px 0;
  }

  footer p {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }

  ul.socil li a img {
    width: 100%;
    max-width: 100%;
  }

  .head .leftdiv img {
    width: 100%;
    max-width: 100%;
  }

  .head .leftdiv ul.nav {
    margin: 0 0 0 30px;
  }
  .hero-tab-content .hero-tab-img {
    padding: 0 10px;
  }
  .chat-model {
    width: 50%;
  }
  /* .head .leftdiv {
    width: 50%;
  } */
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .play-video {
    width: 470px;
    height: 364px;
    padding: 32px 14px;
  }

  .trailer-container iframe {
    height: 430px;
  }
  video {
    border-radius: 36px;
  }
  .chat-model {
    width: 50%;
  }
  .rightdiv,
  .head .leftdiv ul.nav {
    display: none;
  }
  section.Sidebar-menu.Open-menu {
    left: 0;
  }

  section.Sidebar-menu {
    display: flex;
  }

  .Sidebar-menu .leftdiv ul.nav,
  .Sidebar-menu .rightdiv {
    display: flex;
  }

  .toggle-menu {
    display: block;
  }
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .head a.log-btn,
  .btn,
  .log-btn {
    padding: 12px;
    margin-right: 10px;
  }

  ul.nav li {
    margin-right: 18px;
  }
  .head .leftdiv > a {
    width: 25%;
  }
  .container {
    width: 100%;
    padding: 0 2%;
  }
  ul.nav a,
  ul.nav li > span {
    font-size: 12px;
  }
  .rightdiv {
    gap: 15px;
    width: 27%;
    justify-content: flex-end;
  }
  .head .leftdiv {
    width: 100%;
    flex: 0 0 83%;
  }
  .hero-tab-content .hero-tab-img {
    padding: 0 10px;
  }
  .topbanner img {
    width: 100%;
    max-width: 40%;
  }

  .topbanner h1 {
    margin: 0;
  }
  .PlayReverse .row .play-video {
    float: right;
  }

  .topbanner p {
    margin: 10px 0;
  }

  section.topbanner {
    padding: 11% 0;
  }

  .topbanner ul {
    width: 50%;
    margin: 0 auto;
  }

  .topbanner ul li img {
    max-width: 100%;
  }

  .newsbox a {
    font-size: 15px;
  }

  h2.h2head {
    font-size: 45px;
  }

  .tab-content {
    height: auto;
    padding: 0;
  }

  .tab-content p,
  .hero-tabs-content p {
    padding: 0 15% 0 0;
    font-size: 11px;
    height: auto;
  }

  .tab-list ul li {
    width: 20%;
  }

  .play-content p {
    padding: 0;
    font-size: 13px;
  }

  .hero-tabs-list ul li {
    padding: 0 14px;
  }

  .hero-tabs-list ul {
    padding: 8px 40px 0 40px;
  }

  .hero-tabs-list ul li.active {
    width: 17%;
  }

  .hero-tab-content {
    padding: 0;
  }

  .hero-tab-img img {
    max-width: 88%;
  }

  .play-image img {
    margin: inherit;
    max-width: 83%;
  }

  .roadmap p.center {
    width: 50%;
    font-size: 15px;
    margin: 30px auto;
  }

  .road-item-list {
    padding: 10% 0 0 0;
  }

  .map-item {
    padding: 0 50px 0 0;
  }

  .map-item:before {
    top: 52px;
  }
  .map-item h4 {
    font-size: 15px;
  }

  .roadmap-img img {
    max-width: 38px;
  }

  body
    .road-item-list
    .slick-list
    .slick-track
    .slick-slide:first-child
    .map-item.active:after {
    top: 20%;
  }

  body .road-item-list .slick-list .slick-track {
    margin-left: 10%;
  }

  .road-item-list .slick-next {
    right: 90px;
  }
  .fight-ground {
    height: 360px;
  }
  .road-item-list .slick-prev {
    right: 140px;
  }
  body .Sidebar-menu .rightdiv,
  body .Sidebar-menu .leftdiv ul.nav {
    display: block;
    text-align: center;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .play-video {
    width: 570px;
    height: 420px;
  }
  .newsde {
    height: 140px;
  }
  video {
    border-radius: 35px;
  }
  .head .leftdiv > a {
    width: 160px;
  }
  .fight-ground {
    height: 400px;
  }

  .hero-tabs-list ul {
    height: 170px;
  }

  .container {
    width: 100%;
    padding: 0 5%;
  }
  .newsmain .slick-slide {
    padding: 0 12px;
  }

  .newsbox a {
    font-size: 17px;
  }

  h2.h2head {
    font-size: 70px;
  }

  .tab-content p,
  .hero-tabs-content p {
    font-size: 15px;
    line-height: 27px;
    /* height: 180px; */
  }

  .tab-list ul {
    width: 90%;
  }

  .tab-list ul li {
    width: 17%;
  }
  .play-content p {
    padding: 0;
    font-size: 15px;
  }

  .hero-tabs-list ul li.active {
    width: 15%;
  }

  .hero-tabs-list ul li {
    padding: 0 23px;
  }

  /* section.ChooseHeor {
    padding: 0;
  } */

  .hero-tabs {
    width: 100%;
    padding: 14% 0 0 0;
  }

  .hero-tab-content {
    padding: 0 7%;
  }

  h3.tabhead {
    font-size: 20px;
  }

  .tab-content {
    padding: 0 0;
  }

  .road-item-list {
    padding: 8% 0 0 0;
  }

  .roadmap p.center {
    margin: 20px auto;
  }

  .map-item {
    padding: 0 50px 0 0;
  }
}
