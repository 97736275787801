.d-search-box {
  display: flex;
  align-self: stretch;
  justify-content: center;
  gap: 15px;
  width: 500px;
}

.d-search-box .input-field {
  width: 100%;
  margin: 0;
  border: 1px solid #e0e0ff1f;
}

.d-search-box span.search-btn {
  width: 12%;
  display: inline-flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  border: 1px solid #ffaa00;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s ease;
}

.d-search-box span.search-btn:hover {
  background: #32363c;
}

.d-search-box span.search-btn img {
  width: 100%;
  max-width: 20px;
}
