.d-model-text.tele-modal a {
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  padding: 16px 0px;
  border-radius: 80px;
}

.tele-modal a.d-gradient-text.d-nav-link {
  font-size: 14px;
}
.combat-drive
  .d-model-content{
    height: auto;
  }
  .d-model-desc{
    padding-bottom: 20px;
    height: 100px;
  }
  .combat-drive .d-bg-secondary-contained{
    
    position: relative;
    padding: 20px 20px !important;
    height: auto;
}
  
  /* .combat-drive .flex {
    flex-direction: column;
} */
 .combat-drive.step-modal button.d-bg-secondary-contained.d-btn:hover {
    transform: translateY(0px);
    background: none;
    box-shadow: none;
}

.combat-drive.step-modal button.d-bg-secondary-contained.d-btn:hover .d-gradient-text {
    background: linear-gradient(180deg, rgba(238, 255, 0, 1) 0%, rgba(255, 170, 0, 1) 51%, rgba(255, 94, 0, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
} 
@media screen and (min-width: 320px) and (max-width: 767px) {
  .combat-drive .flex{
    flex-direction: column;
  }
  .combat-drive .flex button{
    height: 46px;
}

  .d-model-desc{
    height: auto;
    padding-bottom: 0px;
  }
}
@media screen and (min-width: 568px) and (max-width: 767px) {}
@media screen and (min-width: 768px) and (max-width: 1023px) {}
@media screen and (min-width: 1024px) and (max-width: 1365px) {}
@media screen and (min-width: 1366px) and (max-width: 1600px) {}