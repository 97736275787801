.reward-modal {
  position: fixed;
  width: 95%;
  bottom: 20px;
  z-index: 999;
  left: 0;
  padding: 18px;
  border-radius: 24px;
  right: 0;
  margin: auto;
  background: #000000cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  backdrop-filter: blur(11px);
}

.reward-modal div {
  display: flex;
  align-items: center;
  gap: 18px;
}

.reward-modal p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #fff;
}

.reward-modal img.reward-close-icon {
  width: 100%;
  max-width: 20px;
  cursor: pointer;
}
