.pagination {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.pagination button {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  background: none;
  border: none;
  color: #ffffff8f;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.pagination button:hover,
.pagination button:focus {
  background: linear-gradient(
    180deg,
    rgba(238, 255, 0, 1) 0%,
    rgba(255, 170, 0, 1) 51%,
    rgba(255, 94, 0, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.pagination button.disabled:hover {
  filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7453%)
    hue-rotate(246deg) brightness(116%) contrast(122%);
  opacity: 0.6;
}
.pagination span {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.5s ease;
  background: linear-gradient(45deg, transparent, transparent);
}

.pagination span.active,
.pagination span:hover {
  background: linear-gradient(135deg, #eeff00 0%, #ffaa00 50.81%, #ff5e00 100%);
  color: #fff;
}

.pagination button.disabled {
  cursor: no-drop;
}

.pagination button.disabled:hover {
  color: #ffffff8f !important;
  background-clip: unset;
  background: transparent;
}
