.dashboard-layout {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  z-index: 9;
  display: flex;
  overflow: hidden;
}

.dashboard-layout:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: hsl(218deg 11% 15% / 80%);
  top: 0;
  z-index: -1;
}

.dash-bg-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: -2;
  border-radius: 0 !important;
}

.dawer-sidebar {
  width: 250px;
  background: #ffffff0f;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px rgb(0 0 0 / 12%);
  overflow-x: auto;
  z-index: 1;
}

.d-dash-content {
  width: 100%;
  transition: margin-left 500ms;
  margin-left: 250px;
}

.dawer-sidebar .d-logos {
  width: 100%;
}

.dawer-sidebar .d-logos span img {
  width: 100%;
}

.dawer-sidebar .d-logos span {
  width: 100%;
  display: block;
  padding: 0px 50px;
  box-sizing: border-box;
  transition: padding 500ms;
}

.active-siderbar .dawer-sidebar {
  width: 100px;
  padding: 40px 10px;
}

.active-siderbar .dawer-sidebar .d-logos {
  flex-direction: column;
  gap: 10px;
}

.active-siderbar .dawer-sidebar .d-logos span {
  width: 100%;
  padding: 0;
}

.d-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 20px;
  background: #ffffff0f;
  border-bottom: 1px solid #ffffff0f;
}

.dashboard-layout.active-siderbar .d-dash-content {
  width: 100%;
  margin-left: 100px;
}

.d-bsr-close,
.d-bsr-back {
  width: 40px;
  height: 40px;
  margin-left: 0;
  margin-right: auto;
  position: relative;
  background: transparent;
  border: 2px solid #ff8900;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}
.d-bsr-close img,
.d-bsr-back img {
  width: 50%;
}
.menu-list {
  margin-top: 50px;
  overflow: hidden;
}
.menu-list li {
  margin-bottom: 10px;
}
.dashboard-layout.active-siderbar .menu-list li span {
  flex-direction: column;
  white-space: normal;
  padding: 20px 0px;
}

.dashboard-layout.active-siderbar .menu-list li span div {
  font-size: 11px;
  margin: 8px 0 0 0;
  text-align: center;
  white-space: break-spaces;
}

.dashboard-layout.active-siderbar .menu-list li span div span {
  position: absolute;
  top: 6px;
  right: 16px;
}

body .refer-card {
  padding: 20px;
}

.refer-card .d-btn {
  width: 100%;
}

.refer-card h2 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat";
}
.input-node-icon {
  cursor: pointer;
}
.refer-card p {
  font-size: 12px;
  font-family: "Montserrat";
  color: #ffff;
  font-weight: 500;
  line-height: 16px;
}

.d-content {
  padding: 40px 25px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.dashboard-layout div::-webkit-scrollbar {
  width: 3px;
}

.dashboard-layout div::-webkit-scrollbar-thumb {
  background-color: #ff7d00;
  outline: 1px solid #ff7d00;
}

.active-siderbar .d-bsr-close {
  transform: rotateY(185deg);
}

.flex-head {
  display: flex;
  align-items: center;
  gap: 30px;
  /* width: 70%; */
  justify-content: flex-end;
}
.d-header .flex:nth-child(2) {
  gap: 40px;
}

.d-profile-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px 4px 4px;
  background: rgb(224 224 255 / 5%);
  border-radius: 16px;
  position: relative;
  /* min-width: 180px; */
  cursor: pointer;
}

.d-profile-card .d-profile-content p {
  /* text-overflow: ellipsis; */
  /* width: 5rem; */
  overflow: hidden;
  white-space: nowrap;
  margin: 4px 0;
}

.d-notification-icon {
  padding: 8px 10px;
  border-radius: 12px;
  background: rgb(224 224 255 / 5%);
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
}

.d-notification-icon:hover {
  background: #e0e0ff38;
}

.coin-list {
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.coin-list span {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
  color: rgb(255 255 255 / 60%);
}

.coin-list img {
  width: 100%;
  max-width: 21px;
}

p.d-head-price {
  font-size: 12px;
  font-weight: 700;
  font-family: "Roboto";
  color: #fff;
  line-height: 20px;
}

.d-profile-card .d-profile-image {
  position: relative;
  width: 46px;
  height: auto;
  border-radius: 50%;
  /* background: linear-gradient(135deg, #ef0, #fa0 50.81%, #ff5e00); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-profile-card .d-profile-image img {
  width: 97%;
  height: 90%;
  border-radius: 50%;
  object-fit: contain;
  position: relative;
}

.d-profile-image.profile-border-line::before {
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  background: url("../../Assets/images/Ellipse\ 85.svg");
  top: -0.5px;
  right: -26px;
  background-repeat: no-repeat;
  z-index: 1;

}
.d-profile-image.profile-border-line{
  position: relative;
}
.d-profile-image.profile-border-line::after {
  position: absolute;
  content: '';
  width: 40px;
  height: 50px;
  background: url("../../Assets/images/Ellipse\ 84.svg");
  top: -4.5px;
  right: -2px;
  background-repeat: no-repeat;
  z-index: 1;

}

.menu-list li:last-child span:hover img,
.menu-list li:last-child span.active img {
  filter: none;
}

.d-tool-tip {
  position: absolute;
  top: 42px;
  padding: 2px 10px;
  border: 1px solid #ffaa00;
  background: #212429;
  border-radius: 220px;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 700;
  color: #ffffff;
  margin: -9px 0 0 0;
}

.d-tool-tip-counter {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border: 1px solid #ffaa00;
  background: #212429;
  border-radius: 50%;
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 700;
  color: #ffffff;
}

.d-profile-card .d-profile-content h4 {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #fff;
  width: 97%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.d-profile-content {
  width: 60%;
  padding: 0 0 0 10px;
}

.isOnline {
  background: -webkit-linear-gradient(
    352deg,
    #66ff00 0%,
    #16b862 83.88%,
    #00858f 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  text-wrap-mode: nowrap;
}

.isOnline:before {
  content: "";
  width: 6px;
  height: 6px;
  display: block;
  position: relative;
  bottom: 0;
  top: 0;
  background: linear-gradient(135deg, #66ff00 0%, #16b862 51.88%, #00858f 100%);
  border-radius: 50px;
}

.input-field {
  display: flex;
  align-items: center;
  background: #ffffff0a;
  border-radius: 12px;
  padding: 0 10px 0 0;
  margin-bottom: 12px;
}

.input-field input {
  width: 100%;
  padding: 17px 10px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}
.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.d-toggle-menu,
.d-bsr-back {
  display: none;
}
.hrad-text {
  color: #fff;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 800;
  text-transform: uppercase;
}

.notification-wrapper {
  visibility: hidden;
  position: absolute;
  width: 460px;
  border: 1px solid #ffffff36;
  padding: 20px 20px;
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  z-index: 9;
  top: 65px;
  right: -10px;
}
.notification-wrapper
  .notification-list
  li.not-item
  .d-notification-icon:hover {
  background: rgb(224 224 255 / 5%);
}

.notification-wrapper
  .notification-list
  li.not-item
  .d-notification-icon:hover
  img {
  filter: none;
}

.notification-wrapper .notification-list li.not-item div:last-child {
  width: 86%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.notification-wrapper .notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-wrapper .notification-list li.not-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 14px;
  background: #ffffff0f;
  border-radius: 12px;
  margin-bottom: 7px;
  overflow: hidden;
  position: relative;
}

.notification-wrapper .notification-list li.not-item:before {
  content: "";
  position: absolute;
  width: 65%;
  height: 100px;
  border-radius: 101px;
  -webkit-backdrop-filter: blur(71px);
  opacity: 0.5;
  top: 60px;
  left: 50px;
  background: linear-gradient(135deg, #66ff00 0%, #16b862 51.88%, #00858f 100%);
  filter: blur(20px);
}

.notification-wrapper .notification-list li.not-item h3.not-title {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 6px;
}

.notification-wrapper .notification-list li.not-item p {
  margin: 0px 0 0 0;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff99;
  font-family: "Montserrat";
  flex: 1;
}

.notification-wrapper .notification-list {
  margin-top: 11px;
  height: 350px;
  overflow-y: auto;
  padding-right: 12px;
}
.notification-wrapper .notification-list::-webkit-scrollbar {
  width: 1px;
}
.notification-wrapper .notification-list::-webkit-scrollbar-thumb {
  background-color: #ffffff33;
  outline: 1px solid #ffffff33;
}

.notification-wrapper .notification-list li.not-item span {
  font-size: 12px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #ffffff99;
  white-space: nowrap;
}

.noty-active {
  visibility: visible;
}
.notification-wrapper .notification-header img {
  cursor: pointer;
}

/* .active-siderbar .dawer-sidebar .d-profile-card {
  display: none;
} */

.dawer-sidebar .d-profile-card {
  margin-top: 20px;
}

.d-wraper-modal-open .d-header {
  position: relative;
  z-index: -1;
}
.notfication-col {
  position: relative;
}
.notification-wrapper .notification-list li.not-item .d-notification-icon {
  transition: none;
}

.flex.sign-in-flex {
  justify-content: space-between;
  border-bottom: 1px solid #ffaa00;
  border-radius: 10px;
  padding: 0 5px;
  margin-top: 10px;
}

.flex.sign-in-flex a.sign-btn {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  width: 50%;
  padding: 9px 0;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.flex.sign-in-flex a.sign-btn:first-child {
  color: #fff;
}

.flex.sign-in-flex a.sign-btn:hover {
  background: #4e4f5059;
  border-radius: 7px;
  color: #fff;
}
.opacity-down-disable {
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
}
.pro-card-wrapper {
  box-shadow: 2px 0px 14px 0px #0000001f;
  border: 1px solid #ffffff99;
  border-radius: 16px;
  padding: 20px;
  background: linear-gradient(
    135deg,
    #335328 0%,
    #213c3a 51.88%,
    #00858f29 100%
  );
  position: absolute;
  top: 85px;
  z-index: 999;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  width: 100%;
  left: 0;
  box-sizing: border-box;
}
.out-side-closer {
  position: relative;
}

.pro-card-wrapper li {
  display: flex;
  gap: 10px;
  text-transform: uppercase;
  color: #ffffff87;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  transition: all 0.5s ease;
  align-items: center;
  margin-bottom: 20px;
}
.pro-card-wrapper li:last-child {
  margin-bottom: 0px;
}
.mobile-back {
  display: none !important;
}

.menu-list li:last-child img {
  width: 100%;
  max-width: 25px;
}
.dashboard-layout.active-siderbar .flex.sign-in-flex {
  flex-direction: column;
  border: none;
}

.dashboard-layout.active-siderbar .flex.sign-in-flex a.sign-btn {
  border-bottom: 1px solid #fa0;
  border-radius: 10px;
  padding: 10px 0;
}

.dashboard-layout.active-siderbar .flex.sign-in-flex a {
  width: 100%;
}

.rank-tooltip {
  position: absolute;
  top: 48px;
  border-radius: 8px;
  padding: 4px 10px;
  text-align: center;
}

.rank-tooltip h4 {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff99;
}
.d-notification-icon.active {
  background: #e0e0ff38;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .eran-modal h5 {
    margin-top: 0;
    font-size: 13px;
  }

  .eran-modal .btn-group button.d-bg-primary-contained.d-btn {
    height: 40px;
  }

  .eran-modal .btn-group button.d-bg-secondary-contained.d-btn {
    height: 40px;
  }
  a.mobile-back {
    display: flex !important;
    width: 40% !important;
    justify-content: space-around;
  }
  .d-header .rakec {
    display: none;
  }

  a.mobile-back img {
    max-width: 8px;
  }

  .notification-wrapper {
    width: 320px;
    right: 0px;
    top: 55px;
    padding: 20px 10px;
  }
  .dash-bg-video {
    object-fit: none;
  }

  .notification-wrapper .notification-list li.not-item div.d-notification-icon {
    width: 36px;
  }

  .notification-wrapper .notification-list li.not-item {
    padding: 10px 10px;
    gap: 16px;
  }

  .notification-wrapper .notification-list li.not-item p {
    font-size: 10px;
    margin-top: 3px;
  }

  .notification-wrapper .notification-list li.not-item div {
    width: 80%;
  }

  .notification-wrapper .notification-list li.not-item span {
    font-size: 10px;
  }

  .notification-wrapper .notification-list li.not-item h3.not-title {
    font-size: 11px;
  }
  .dawer-sidebar {
    left: -100%;
  }
  .d-top-bar .d-top-left {
    width: 100%;
  }
  .dawer-sidebar.d-active-siderbard {
    backdrop-filter: blur(51px);
    width: 100%;
    position: fixed;
    background: #0000005c;
    z-index: 999;
    left: 0;
  }

  button.d-bsr-back {
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body .dawer-sidebar .d-logos,
  .active-siderbar .dawer-sidebar .d-logos {
    display: flex;
    flex-direction: row-reverse;
    align-items: center !important;
    justify-content: space-between;
  }

  body .dawer-sidebar .d-logos span,
  .active-siderbar .dawer-sidebar .d-logos span {
    width: 20%;
    padding: 0;
  }
  .d-menu-link {
    padding: 17px 20px;
  }

  button.d-bsr-back img {
    width: 92%;
  }

  .d-bsr-close {
    display: none;
  }
  .d-toggle-menu,
  .d-bsr-back {
    display: block;
  }
  .d-dash-content,
  .dashboard-layout.active-siderbar .d-dash-content {
    margin: 0;
  }

  .d-header .d-profile-content {
    display: none;
  }

  .d-header .d-profile-card {
    min-width: 80px;
    justify-content: space-around;
  }

  .d-header .d-profile-card .d-profile-image {
    width: 100%;
  }

  .d-profile-card.d-fit-w .d-profile-content {
    display: block;
  }

  .d-header .d-profile-card.d-fit-w {
    display: none;
  }

  .d-header .coin-list {
    display: none;
  }

  .d-header p.d-head-price {
    display: none;
  }

  .d-header .d-btn {
    display: none;
  }

  .flex-head {
    width: fit-content;
    gap: 10px;
  }

  .d-header .d-tool-tip {
    font-size: 9px;
  }

  .d-top-bar h2 {
    font-size: 17px !important;
  }

  .d-top-bar .d-top-left p {
    font-size: 10px;
    line-height: 15px;
    padding: 0 30px 0 0;
  }

  body .d-top-bar .d-top-right .d-bg-secondary-contained.d-btn {
    padding: 18px 15px !important;
    font-size: 12px;
  }

  .d-header .d-nav-link {
    display: none;
  }

  .d-content {
    padding: 20px 10px;
  }

  .d-top-bar {
    flex-direction: column;
  }

  .d-content .d-task-list {
    grid-template-columns: repeat(1, 1fr);
    padding-right: 0;
  }

  .d-task-card .d-task-content h3 {
    height: auto;
    padding: 0;
  }

  .d-top-bar .d-top-right {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
  .notification-wrapper .notification-list {
    height: 150px;
  }
  .dash-bg-video {
    object-fit: none;
  }
  .notification-wrapper {
    width: 390px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dawer-sidebar {
    left: -100%;
  }

  .dash-bg-video {
    object-fit: none;
  }

  .dawer-sidebar.d-active-siderbard {
    backdrop-filter: blur(51px);
    width: 100%;
    position: fixed;
    background: #0000005c;
    z-index: 999;
    left: 0;
  }

  button.d-bsr-back {
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dawer-sidebar .d-logos {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .dawer-sidebar .d-logos span {
    width: 10%;
    padding: 0;
  }
  .d-menu-link {
    padding: 17px 20px;
  }

  button.d-bsr-back img {
    width: 92%;
  }
  .dawer-sidebar.d-active-siderbard {
    backdrop-filter: blur(51px);
    display: block;
    width: 100%;
    position: fixed;
    background: #0000005c;
    z-index: 999;
  }

  button.d-bsr-back {
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dawer-sidebar .d-logos {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .dawer-sidebar .d-logos span {
    width: 10%;
    padding: 0;
  }

  .d-menu-link {
    padding: 17px 20px;
  }

  button.d-bsr-back img {
    width: 92%;
  }
  .d-bsr-close {
    display: none;
  }
  .d-toggle-menu,
  .d-bsr-back {
    display: block;
  }
  .d-dash-content,
  .dashboard-layout.active-siderbar .d-dash-content {
    margin: 0;
  }

  .d-header .d-profile-content {
    display: none;
  }

  .d-header .d-profile-card {
    min-width: 80px;
    justify-content: space-around;
  }

  .d-header .d-profile-card .d-profile-image {
    width: 100%;
  }

  .d-profile-card.d-fit-w .d-profile-content {
    display: block;
  }

  .d-header .out-side-closer {
    display: none;
  }
  .d-header .flex:nth-child(2) {
    gap: 11px;
  }

  .coin-list span {
    font-size: 12px;
  }

  /* .d-header .coin-list {
    display: none;
  }

  .d-header p.d-head-price {
    display: none;
  } */

  .d-header .d-btn {
    padding: 14px 13px;
    height: auto;
    font-size: 10px;
    display: none;
  }

  .flex-head {
    width: fit-content;
  }

  .d-header .d-tool-tip {
    font-size: 9px;
  }

  .d-top-bar h2 {
    font-size: 17px;
  }

  .d-top-bar .d-top-left p {
    font-size: 10px;
    line-height: 15px;
    padding: 0 30px 0 0;
  }

  body .d-top-bar .d-top-right .d-bg-secondary-contained.d-btn {
    padding: 18px 15px !important;
    font-size: 12px;
  }

  .d-task-card .d-icon {
    margin: 16px auto;
  }

  .notification-wrapper {
    /* right: -90px; */
    width: 400px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
  .dawer-sidebar {
    left: -100%;
  }
  .dash-bg-video {
    object-fit: none;
  }
  .notification-wrapper {
    right: 0px;
  }
  .dawer-sidebar.d-active-siderbard {
    backdrop-filter: blur(51px);
    width: 100%;
    position: fixed;
    background: #0000005c;
    z-index: 999;
    left: 0;
  }
  .dawer-sidebar.d-active-siderbard .d-profile-content {
    width: 92%;
  }

  button.d-bsr-back {
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dawer-sidebar .d-logos {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .dawer-sidebar .d-logos span {
    width: 10%;
    padding: 0;
  }
  .d-menu-link {
    padding: 17px 20px;
  }

  button.d-bsr-back img {
    width: 92%;
  }
  .dawer-sidebar.d-active-siderbard {
    backdrop-filter: blur(51px);
    display: block;
    width: 100%;
    position: fixed;
    background: #0000005c;
    z-index: 999;
  }

  button.d-bsr-back {
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dawer-sidebar .d-logos {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .dawer-sidebar .d-logos span {
    width: 10%;
    padding: 0;
  }

  .d-menu-link {
    padding: 17px 20px;
  }

  button.d-bsr-back img {
    width: 92%;
  }
  .d-bsr-close {
    display: none;
  }
  .d-toggle-menu,
  .d-bsr-back {
    display: block;
  }
  .d-dash-content,
  .dashboard-layout.active-siderbar .d-dash-content {
    margin: 0;
  }

  .d-header .d-profile-content {
    display: none;
  }

  .d-header .d-profile-card {
    min-width: 80px;
    justify-content: space-around;
  }

  .d-header .d-profile-card .d-profile-image {
    width: 100%;
  }

  .d-profile-card.d-fit-w .d-profile-content {
    display: block;
  }

  .d-header .d-profile-card.d-fit-w {
    min-width: fit-content;
  }
  /* 
  .d-header .coin-list {
    display: none;
  }

  .d-header p.d-head-price {
    display: none;
  } */

  .d-header .d-btn {
    padding: 14px 13px;
    height: auto;
    font-size: 10px;
  }

  .flex-head {
    width: fit-content;
  }

  .d-header .d-tool-tip {
    font-size: 9px;
  }

  .d-top-bar h2 {
    font-size: 17px;
  }

  .d-top-bar .d-top-left p {
    font-size: 10px;
    line-height: 15px;
    padding: 0 30px 0 0;
  }

  body .d-top-bar .d-top-right .d-bg-secondary-contained.d-btn {
    padding: 18px 15px !important;
    font-size: 12px;
  }
}
